import { MutationTree } from 'vuex';
import { GroupState } from './types';
import { Journal } from '@/interfaces/journal/journal';
import { Round, RoundShort } from '@/interfaces/rounds/rounds';
import { User } from '@/interfaces/User';
import { Handout } from '@/interfaces/handouts/handouts';
import { CharacterShortData, CharacterExportData } from '@/classes/character/character';
import Vue from 'vue';
import { BattleData, BattleFieldData } from '@/interfaces/group/battle';

export const mutations: MutationTree<GroupState> = {
  addQuest(state, quest) {
    if (state.journal === null) {
      state.journal = { quests: [] };
    }
    state.journal.quests.push(quest);
  },

  addQuestTask(state, payload) {
    const questTask = payload.questTask;
    const questId = payload.questId;
    if (state.journal === null) {
      throw new Error('Journal is null while adding QuestTask');
    }
    const questIndex = state.journal.quests.findIndex((existingQuest) => existingQuest.id === questId);
    if (questIndex === -1) {
      throw new Error('Could not find quest with id ' + questId);
    }
    state.journal.quests[questIndex].tasks.push(questTask);
  },

  addQuestTaskDescription(state, payload) {
    const questTaskDesc = payload.questTaskDescription;
    const questTaskId = payload.questTaskId;
    const questId = payload.questId;
    if (state.journal === null) {
      throw new Error('Journal is null while adding QuestTask');
    }
    const questIndex = state.journal.quests.findIndex((existingQuest) => existingQuest.id === questId);
    if (questIndex === -1) {
      throw new Error('Could not find quest with id ' + questId);
    }
    const tasks = state.journal.quests[questIndex].tasks;
    const questTaskIndex = tasks.findIndex((existingTask) => existingTask.id === questTaskId);
    if (questTaskIndex === -1) {
      throw new Error('Could not find quest with id ' + questId);
    }
    state.journal.quests[questIndex].tasks[questTaskIndex].descriptions.push(questTaskDesc);
  },

  setJournal(state, journal: Journal) {
    state.journal = journal;
  },

  setJournalState(state, journalState) {
    state.journalState = journalState;
  },

  setRoundState(state, roundState) {
    state.roundState = roundState;
  },

  setBattleState(state, battleState: BattleData) {
    state.battleState = battleState;
  },

  setSessionState(state, sessionState) {
    state.session = sessionState;
  },

  setRoundShorts(state, roundShorts: RoundShort[]) {
    state.roundsShort = roundShorts;
  },

  setRound(state, round: Round) {
    // First check if that round is already present
    const index = state.rounds.findIndex((localRound) => localRound.id === round.id);

    if (index > -1) {
      state.rounds[index] = round;
    } else {
      state.rounds.push(round);
    }

    // do we need to add it to the shorts as well?
    const roundShortIndex = state.roundsShort.findIndex((localRound) => localRound.id === round.id);
    const roundShort: RoundShort = {
      id: round.id,
      title: round.title,
      date: round.date,
    };
    if (roundShortIndex > -1) {
      state.roundsShort[roundShortIndex] = roundShort;
    } else {
      state.roundsShort.push(roundShort);
    }
  },

  setGroupMembers(state, groupMembers: User[]) {
    state.members = groupMembers;
  },
  setGroupMasters(state, masters: string[]) {
    state.masters = masters;
  },
  addOrUpdateCharacter(state, newCharacter: CharacterExportData) {
    // Check if this character is already present
    const index = state.characters.findIndex((character) => character.characterId === newCharacter.characterId);

    if (index < 0) {
      state.characters.push(newCharacter);
    } else {
      Vue.set(state.characters, index, newCharacter);
    }
  },
  setGroupCharacterShorts(state, characterShorts: CharacterShortData[]) {
    state.characterShorts = characterShorts;
  },
  setHandouts(state, handouts: Handout[]) {
    state.handouts = handouts;
  },

  setHandout(state, handout: Handout) {
    // First check if that round is already present
    const index = state.handouts.findIndex((localHandout) => localHandout.id === handout.id);

    if (index > -1) {
      // Use Vue.set because direct array access is not reactive
      Vue.set(state.handouts, index, handout);
    } else {
      state.handouts.push(handout);
    }
  },
  removeHandout(state, handout: Handout) {
    // Find the index of the handout
    const index = state.handouts.findIndex((localHandout) => localHandout.id === handout.id);

    // If it is in the store, remove it. If not, don't do anything.
    if (index > -1) {
      state.handouts.splice(index, 1);
    }
  },
  setBattleImages(state, battleImages: BattleFieldData[]) {
    state.battleImages = battleImages;
  },
  addBattleImage(state, battleImage: BattleFieldData) {
    state.battleImages.push(battleImage);
  },
};
