export interface MainRoute {
  title: string;
  icon: string[];
  children: ChildRoute[];
}

export interface ChildRoute {
  title: string;
  icon: string[];
  route: string;
  disabledIf?: string;
}

export const routes: MainRoute[] = [
  {
    title: 'Welt',
    icon: ['fas', 'globe-stand'],
    children: [
      { title: 'Weltkarte', icon: ['fal', 'compass'], route: '/karte', disabledIf: 'no-world' },
      { title: 'Datenbank', icon: ['fas', 'book-open'], route: '/welt', disabledIf: 'no-world' },
      { title: 'Bibliothek', icon: ['fas', 'books'], route: '/bibliothek', disabledIf: 'no-world' },
      { title: 'Audiothek', icon: ['fas', 'comment'], route: '/audiothek', disabledIf: 'no-world' },
      // { title: 'Regeln', icon: ['fas', 'hand-point-right'], route: '/regeln', disabledIf: 'no-world' },
      // { title: 'Bestiarium', icon: ['fas', 'paw-claws'], route: '/bestiarium', disabledIf: 'no-world' },
    ],
  },
  {
    title: 'Spielgruppe',
    icon: ['fas', 'dice-d20'],
    children: [
      // { title: 'Charaktere', icon: ['fas', 'users'], route: '/charakter', disabledIf: 'no-group' },
      { title: 'Kämpfe', icon: ['far', 'swords'], route: '/dashboard', disabledIf: 'no-group' },
      { title: 'Spielkarte', icon: ['fas', 'game-board'], route: '/spielkarte', disabledIf: 'no-group' },
      { title: 'Questlog', icon: ['fas', 'exclamation'], route: '/journal', disabledIf: 'no-group' },
      { title: 'Spielabende', icon: ['fas', 'calendar'], route: '/spielabende', disabledIf: 'no-group' },
      { title: 'Handouts', icon: ['fas', 'scroll'], route: '/handouts', disabledIf: 'no-group' },
      // { title: 'Gruppe wechseln', icon: ['fas', 'exchange-alt'], route: '/spielgruppen' },
    ],
  },
];
