import firebase from 'firebase/app';
import 'firebase/firestore';
import FirebaseGateway from '@/classes/firebaseGateway';
import FirebaseStorageLoader from '@/classes/firebaseStorageLoader';
import { Person } from '@/interfaces/Person';
import { UserLog } from '@/interfaces/UserLog';
import { LibraryItem } from '@/interfaces/world/library';
import { Handout } from '@/interfaces/handouts/handouts';
import { CreateUserPayload } from './payloads';

export default {
  setUser({ commit }, user) {
    commit('setUser', user);
  },

  loadUserData({ dispatch, rootGetters }) {
    if (rootGetters.userId === null) {
      throw new Error('User is null, cannot load userData');
    }
    // In case use was already loaded, return data
    if (rootGetters.userData !== null) {
      return Promise.resolve(rootGetters.userData);
    }

    return FirebaseGateway.loadUser(rootGetters.userId).then((userData) => {
      dispatch('setUserData', userData);
      if (userData.currentGroup !== null) {
        if (userData.currentWorld !== null) {
          dispatch('loadCurrentWorld');
        }
        return dispatch('loadGroupData');
      }
      return userData;
    });
  },

  createUser({ commit, dispatch }, payload: CreateUserPayload) {
    return FirebaseGateway.createUser(payload.email, payload.password, payload.username).then((returnPayload) => {
      dispatch('setUserData', returnPayload.userData);
      commit('setUser', returnPayload.user);
    });
  },

  setUserData({ commit }, userData) {
    commit('setUserData', userData);

    // This is on world module
    if (typeof userData.currentWorld !== 'undefined') {
      commit('setCurrentWorld', userData.currentWorld);
    }

    return true;
  },

  async loadGroupData({ commit, rootGetters }) {
    if (!rootGetters.currentGroup) {
      throw new Error('Cannot load groupData without groupId');
    }
    const groupData = await firebase
      .firestore()
      .collection('groups')
      .doc(rootGetters.currentGroup)
      .get();
    commit('setCurrentGroupData', groupData.data());
    return true;
  },

  resetFight({ commit }) {
    commit('resetFight');
  },

  deletePerson({ state, commit }, id) {
    if (state.currentGroup === null) {
      throw new Error('No group selected');
    }
    return firebase
      .firestore()
      .collection('database')
      .doc(state.currentGroup)
      .collection('people')
      .doc(id)
      .delete()
      .then(() => {
        commit('deletePerson', id);
      });
  },

  saveWorldPerson({ state, commit, dispatch }, person: Person) {
    if (state.currentGroup === null) {
      throw new Error('No group selected');
    }
    const peopleCollection = firebase
      .firestore()
      .collection('database')
      .doc(state.currentGroup)
      .collection('people');
    if (person.hasOwnProperty('id') && person.id !== null) {
      // Remove the loadedImageUrl, because the normal image might have changed
      if (typeof person.loadedImageUrl !== 'undefined' && person.loadedImageUrl !== null) {
        person.loadedImageUrl = null;
      }

      // This person is edited, so store user who edited it
      person.lastUpdatedBy = state.user.uid;

      return peopleCollection
        .doc(person.id)
        .set(person)
        .then(() => {
          if (person.image !== null && person.loadedImageUrl === null) {
            dispatch('loadImageUrl', person);
          }
          commit('addPerson', person);
        });
    } else {
      // Add the current user to this person as the one who added it
      person.addedBy = state.user.uid;

      return peopleCollection.add(person).then((createdDocument) => {
        person.id = createdDocument.id;
        commit('addPerson', person);
      });
    }
  },

  loadImageUrl({ state, commit }, person: Person) {
    FirebaseStorageLoader.fetchPersonImageUrl(state.currentGroup, person)
      .then((url) => {
        person.loadedImageUrl = url;
        commit('addPerson', person);
      })
      .catch((error) => {
        throw new Error(error);
      });
  },

  loadHandoutUrl({ state, commit }, handout: Handout) {
    return FirebaseStorageLoader.fetchHandoutUrl(state.currentGroup, handout.fileName).then((downloadUrl) => {
      handout.downloadUrl = downloadUrl;
      commit('setHandout', handout);
      return downloadUrl;
    });
  },

  loadLibraryItemUrl({ rootGetters, commit }, libraryItem: LibraryItem) {
    let promise: Promise<string>;
    if (typeof libraryItem.isPersonal !== 'undefined' && libraryItem.isPersonal === true) {
      promise = FirebaseStorageLoader.fetchPersonalLibraryUrl(rootGetters.userId, libraryItem.filename);
    } else {
      promise = FirebaseStorageLoader.fetchLibraryUrl(rootGetters.currentWorld, libraryItem.filename);
    }
    return promise.then((downloadUrl) => {
      libraryItem.downloadUrl = downloadUrl;
      commit('setLibraryItem', libraryItem);
      return downloadUrl;
    });
  },

  saveAccountDetails({ state, commit }, details) {
    const gateway = new FirebaseGateway();
    if (details.name) {
      gateway.saveAccountName(state.user.uid, details.name).then(() => {
        commit('setUserName', details.name);
      });
    }
  },

  changeBackground({ state, commit }, payload) {
    firebase
      .firestore()
      .collection('users')
      .doc(state.user.uid)
      .set(
        {
          settings: {
            background: payload.background,
            opacity: payload.opacity,
            colors: payload.colors,
          },
        },
        {
          merge: true,
        },
      )
      .then(() => {
        commit('setTheme', payload);
      });
  },

  signoutUser({ state, commit }) {
    commit('setPersons', []);
    commit('setUser', null);
    commit('setUserData', null);
    return true;
  },

  loadUserLog({ state, commit }) {
    return firebase
      .firestore()
      .collection('userlog/' + state.currentGroup + '/global')
      .orderBy('timestamp', 'desc')
      .limit(10)
      .get()
      .then((querySnapshot) => {
        const userLogs: UserLog[] = [];
        querySnapshot.docs.forEach((doc) => {
          userLogs.push(doc.data() as UserLog);
        });
        commit('addUserLogs', userLogs);
      });
  },
};
