




import Vue from 'vue';
import Component from 'vue-class-component';

@Component
export default class BackgroundHolder extends Vue {
  get backgroundImage(): string {
    const image = this.$store.getters.backgroundImage;
    return 'url(' + require('./assets/css/background/' + image) + ')';
  }

  get backgroundOpacity(): number {
    return this.$store.getters.backgroundOpacity / 100;
  }
}
