// Initialize Firebase
import 'vuetify/dist/vuetify.min.css';
import 'vue-class-component/hooks';
import Vue from 'vue';
import router from './router';
import store from './store/store';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import i18n from './plugins/i18n';
import BackgroundHolder from './BackgroundHolder.vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import './assets/css/style.css';
// import './registerServiceWorker'; Disable service worker
import Bugsnag from '@bugsnag/js';
import BugsnagPluginVue from '@bugsnag/plugin-vue';
import VueI18n from 'vue-i18n';

/**
 * Declare process variable
 */
declare var process: {
  env: {
    VUE_APP_VERSION: string;
    NODE_ENV: string;
    VUE_APP_FIREBASE_TARGET: string;
    BASE_URL: string;
  };
};

// In production mode, enable monitoring
if (process.env.NODE_ENV === 'production') {
  Bugsnag.start({
    apiKey: 'c314eac7ddd691908bd8e233e22dedf9',
    plugins: [new BugsnagPluginVue()],
    appVersion: process.env.VUE_APP_VERSION,
  });

  // @ts-ignore
  Bugsnag.getPlugin('vue')!.installVueErrorHandler(Vue);
}

import {
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faH1,
  faH2,
  faH3,
  faListOl,
  faListUl,
  faHorizontalRule,
  faExternalLink,
  faPlus,
  faSortNumericDown,
  faEye,
  faEyeSlash,
  faClone,
  faPaperclip,
  faEnvelope,
  faConstruction,
  faSwords,
  faEllipsisV,
  faDesktop,
  faList,
  faArrows,
  faSearchPlus,
  faBan,
  faBiohazard,
  faMinus,
} from '@fortawesome/pro-regular-svg-icons';

import { faShield, faEdit, faCheckCircle, faCompass } from '@fortawesome/pro-light-svg-icons';

import {
  faArrowUp,
  faArrowDown,
  faBookOpen,
  faQuoteRight,
  faParagraph,
  faBooks,
  faExclamation,
  faScroll,
  faStarExclamation,
  faSave,
  faTimes,
  faLock,
  faDiceD6,
  faDiceD20,
  faAddressCard,
  faTrash,
  faSignOut,
  faCog,
  faCloudUpload,
  faUserCircle,
  faCheck,
  faSkull,
  faTh,
  faAlignJustify,
  faTombstone,
  faGlobeStand,
  faCalendar,
  faUsers,
  faArchway,
  faDungeon,
  faGopuram,
  faLandmark,
  faMonument,
  faToriiGate,
  faVihara,
  faFistRaised,
  faDragon,
  faPawClaws,
  faPlayCircle,
  faQuestionCircle,
  faCircle,
  faSearch,
  faGameBoard,
  faExchangeAlt,
  faHandPointRight,
  faPortrait,
  faUnlock,
  faPalette,
  faTreasureChest,
  faComment,
  faVolumeHigh,
  faVolumeLow,
  faVolumeXmark,
  faVolumeOff,
  faBackwardStep,
  faForwardStep,
  faStop,
  faPlay,
  faPause,
} from '@fortawesome/pro-solid-svg-icons';

library.add(
  faShield,
  faEdit,
  faCheckCircle,
  faArrowUp,
  faArrowDown,
  faSave,
  faTimes,
  faLock,
  faPlus,
  faMinus,
  faTrash,
  faSignOut,
  faSortNumericDown,
  faEye,
  faEyeSlash,
  faEllipsisV,
  faEnvelope,
  faQuestionCircle,
  faSearch,
  faDiceD6,

  // Navbar
  faCompass,
  faBookOpen,
  faDiceD20,
  faGlobeStand,
  faSwords,
  faScroll,
  faBooks,
  faExclamation,
  faCog,
  faCloudUpload,
  faUserCircle,
  faAddressCard,
  faConstruction,
  faCalendar,
  faUsers,
  faDesktop,
  faExchangeAlt,
  faHandPointRight,
  faComment,

  // Editor items
  faBold,
  faItalic,
  faStrikethrough,
  faUnderline,
  faQuoteRight,
  faH1,
  faH2,
  faH3,
  faListOl,
  faListUl,
  faHorizontalRule,
  faParagraph,
  faPalette,

  // handouts
  faExternalLink,
  faStarExclamation,

  // fight
  faCheck,
  faClone,
  faSkull,

  // Database
  faTh,
  faAlignJustify,
  faTombstone,
  faPaperclip,

  // Map
  faArchway,
  faDungeon,
  faGopuram,
  faLandmark,
  faMonument,
  faToriiGate,
  faVihara,
  faFistRaised,
  faDragon,
  faPawClaws,
  faCircle,

  // Session
  faPlayCircle,

  // Check
  faList,

  // Battleboard
  faGameBoard,
  faArrows,
  faSearchPlus,
  faBan,

  // Group
  faBiohazard,

  // Beastiary
  faPortrait,
  faUnlock,

  // rounds
  faTreasureChest,

  // Soundboard
  faVolumeHigh,
  faVolumeLow,
  faVolumeXmark,
  faVolumeOff,
  faBackwardStep,
  faForwardStep,
  faStop,
  faPlay,
  faPause,
);

Vue.config.productionTip = false;
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

Vue.use(VueI18n);

Vue.filter('minutes', (value: string) => {
  if (!value || typeof value !== 'number') {
    return '00:00';
  }
  const min: number = Math.floor(parseInt(value, 10) / 60);
  const sec: number = Math.round(parseInt(value, 10) % 60);
  const minString: string = min < 10 ? '0' + min : min.toString();
  const secString: string = sec < 10 ? '0' + sec : sec.toString();
  value = minString + ':' + secString;
  return value;
});

new Vue({
  components: { App, BackgroundHolder },
  store,
  vuetify,
  router,
  i18n,
}).$mount('#app');
