export default [
  'cloak',
  'axes',
  'bow-arrow',
  'crossbow',
  'scroll',
  'skull',
  'ring',
  'spell-book',
  'spell-book-open',
  'staff',
  'sword-shield',
  'kunai',
  'rapier',
  'spear',
  'sword',
  'swords-crossed',
  'sabers-crossed',
  'wand',

  'unarmed',
  'sniper',
  'medic',
  'bear',
  'wolf',
  'insect',
  'dragon',
  'zombie',
  'golem',
  'ghost',
  'werewolf',
  'android',
  'demon',
];
