import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import de from 'vuetify/src/locale/de';
import en from 'vuetify/src/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
  theme: { dark: true, themes: { dark: { primary: '#ce6739', secondary: '#52404c', accent: '#375782' } } },
  locale: {
    locales: { de, en },
    current: 'de',
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
