import Vue from 'vue';
import Router from 'vue-router';
import Fight from '@/components/fight/fight.vue';
import Home from '@/components/home.vue';
const Welcome = () => import('@/components/welcome.vue');
const Dashboard = () => import('@/components/dashboard.vue');
const PundPSettings = () => import('@/components/settings.vue');
const Group = () => import('@/components/group/group.vue');
// import CharacterList from '@/components/character/characterList.vue';
// import Session from '@/components/group/session/session.vue';
const PasswordResetForm = () => import('@/components/passwordResetForm.vue');
const NotFound = () => import('@/components/notfound.vue');
import AuthGuard from './auth-guard';
const Library = () => import('@/components/world/library.vue');
const Audiothek = () => import('@/components/world/audiothek.vue');
const Journal = () => import('@/components/journal/journal.vue');
const Rounds = () => import('@/components/group/rounds/rounds.vue');
const Battle = () => import('@/components/group/battle/battle.vue');
const Handouts = () => import('@/components/group/handouts/handouts.vue');
const World = () => import('@/components/world/world.vue');
const WorldMap = () => import('@/components/map/map.vue');
// const Rules = () => import('@/components/world/rules.vue');
// const Bestiary = () => import('@/components/world/bestiary/bestiary.vue');

Vue.use(Router);

export default new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: AuthGuard,
    },
    {
      path: '/home',
      name: 'HomeLoggedIn',
      component: Welcome,
      beforeEnter: AuthGuard,
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      beforeEnter: AuthGuard,
    },
    {
      path: '/welt',
      name: 'Welt',
      component: World,
      beforeEnter: AuthGuard,
    },
    {
      path: '/karte',
      name: 'Karte',
      component: WorldMap,
      beforeEnter: AuthGuard,
    },
    {
      path: '/journal',
      name: 'Journal',
      component: Journal,
      beforeEnter: AuthGuard,
    },
    {
      path: '/settings',
      name: 'Settings',
      component: PundPSettings,
      beforeEnter: AuthGuard,
    },
    {
      path: '/spielgruppen',
      name: 'Group Overview',
      component: Group,
      beforeEnter: AuthGuard,
    },
    {
      path: '/spielgruppen/:groupId',
      name: 'Group View',
      component: Group,
      props: true,
      beforeEnter: AuthGuard,
    },
    {
      path: '/passwordreset',
      name: 'Password Reset',
      component: PasswordResetForm,
    },
    {
      path: '/spielabende',
      name: 'Spielabend Übersicht',
      component: Rounds,
      beforeEnter: AuthGuard,
    },
    {
      path: '/spielabende/:roundTitle',
      name: 'Spielabend',
      props: true,
      component: Rounds,
      beforeEnter: AuthGuard,
    },
    {
      path: '/handouts',
      name: 'Handouts',
      component: Handouts,
      beforeEnter: AuthGuard,
    },
    {
      path: '/bibliothek',
      name: 'Bibliothek',
      component: Library,
      beforeEnter: AuthGuard,
    },
    {
      path: '/audiothek',
      name: 'Audiothek',
      component: Audiothek,
      beforeEnter: AuthGuard,
    },
    {
      path: '/spielkarte',
      name: 'Spielkarte',
      component: Battle,
      beforeEnter: AuthGuard,
    },
    // Catch-all route
    {
      path: '*',
      component: NotFound,
    },

    {
      path: '/fight/:fightId',
      name: 'Fight',
      component: Fight,
      props: true,
      beforeEnter: AuthGuard,
    },
    /*
    * DISABLED ROUTES
    {
      path: '/charakter',
      name: 'Character Creation',
      component: CharacterList,
      props: true,
      beforeEnter: AuthGuard,
    },
    {
      path: '/charakter/:characterId',
      name: 'Character View',
      component: CharacterList,
      props: true,
      beforeEnter: AuthGuard,
    },
    {
      path: '/session',
      name: 'Sitzung',
      component: Session,
      beforeEnter: AuthGuard,
    },
    {
      path: '/regeln',
      name: 'Regeln',
      component: Rules,
      beforeEnter: AuthGuard,
    },
    {
      path: '/bestiarium',
      name: 'Bestiarium',
      component: Bestiary,
      beforeEnter: AuthGuard,
    },
    */
  ],
});
