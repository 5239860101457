import { MutationTree } from 'vuex';
import { FightState } from './types';
import Vue from 'vue';
import FightInterface from '@/interfaces/Fight';
import Migrator from '@/classes/migrator';
import Fighter from '@/interfaces/Fighter';

/**
 * Looks in the current fight for a fighter with the passed id.
 * @param state The current state of the store
 * @param fighterId  The id of the fighter to search for
 */
const getFighter = (state, fighterId): Fighter => {
  if (state.currentFight === null) {
    throw new Error('currentFight is null');
  }
  const index = state.currentFight.fighters.findIndex((localFighter) => {
    return localFighter.id === fighterId;
  });
  if (index === -1) {
    throw new Error('Fighter with id: ' + fighterId + ' not found');
  }
  return state.currentFight.fighters[index];
};

export const mutations: MutationTree<FightState> = {
  /**
   * Sets the passed fight as the current fight of the user
   * @param state
   * @param currentFight
   */

  setCurrentFight(state, currentFight: FightInterface) {
    state.currentFight = currentFight;
  },
  /**
   * Sets the id of the currentfight. Is called when we've created a
   * new fight and got the id back from firebase
   * @param state
   * @param currentFightId
   */

  setCurrentFightId(state, currentFightId) {
    if (state.currentFight === null) {
      throw new Error('setCurrentFightId: current Fight is null');
    }
    state.currentFight.id = currentFightId;
  },
  /**
   * Adds the passed fight to the fights stored.
   * If the id of the fight is already present, overwrite this fight
   * @param state
   * @param fight
   */

  addFight(state, fight) {
    const index = state.fights.findIndex((existingFight) => {
      return existingFight.id === fight.id;
    });
    if (index === -1) {
      state.fights.push(fight);
    } else {
      Vue.set(state.fights, index, fight);
    }
  },
  setFights(state, fights) {
    state.fights = fights;
  },
  removeFight(state, fightId) {
    state.fights = state.fights.filter((localFight) => localFight.id !== fightId);

    // in case this is the currentfight, remove this information as well
    if (state.currentFight != null && state.currentFight.id === fightId) {
      state.currentFight = null;
    }
  },

  cleanFights(state) {
    state.fights = [];
  },
  updateSequenceValue(state, payload) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === payload.fighterId;
    });
    state.currentFight.fighters[index].sequenceValue = payload.value;
  },
  changeNotes(state, payload) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === payload.fighterId;
    });
    state.currentFight.fighters[index].notes = payload.value;
  },
  changeLep(state, payload) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === payload.fighterId;
    });
    state.currentFight.fighters[index].lep = payload.value;
  },
  changeSlots(state, payload) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === payload.fighterId;
    });
    state.currentFight.fighters[index].slots = payload.value;
  },
  addWound(state, payload) {
    const fighter = getFighter(state, payload.fighterId);
    if (fighter.wounds === undefined) {
      fighter.wounds = 0;
    }
    fighter.wounds++;
  },
  healWound(state, payload) {
    const fighter = getFighter(state, payload.fighterId);
    if (fighter.wounds === undefined) {
      fighter.wounds = 0;
    }
    if (fighter.wounds > 0) {
      fighter.wounds--;
    }
  },
  addDot(state, payload) {
    const fighter = getFighter(state, payload.fighterId);
    if (fighter.bleeders === undefined) {
      fighter.bleeders = [];
    }

    fighter.bleeders.push({
      damage: 1,
      turns: 1,
      id: Math.round(Math.random() * 10000),
    });
  },
  addFighter(state, fighter) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    state.currentFight.fighters.push(fighter);
  },
  duplicateFighter(state, fighterId) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === fighterId;
    });
    const newFighter = JSON.parse(JSON.stringify(state.currentFight.fighters[index]));
    newFighter.id = Math.floor(Math.random() * 1000000);

    state.currentFight.fighters.push(newFighter);
  },
  setFighterPreset(state, preset) {
    // Check if that preset already exists and if so, replace it
    const index = state.presets.findIndex((localPreset) => localPreset.id === preset.id);

    if (index > -1) {
      state.presets[index] = preset;
    } else {
      state.presets.push(preset);
    }
  },
  setFighterPresets(state, presets) {
    state.presets = presets;
  },
  /**
   * Switches the status of a fighter
   * @param state
   * @param payload
   */
  toggleFighterActive(state, fighterId) {
    const fighter = getFighter(state, fighterId);
    fighter.inactive = !fighter.inactive;
  },

  killFighter(state, fighterId) {
    const fighter = getFighter(state, fighterId);
    fighter.alive = false;
  },
  removeFighter(state, fighterId) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === fighterId;
    });
    if (index < 0) {
      throw new Error('Fighter ' + fighterId + ' not found in ' + state.currentFight.id);
    }
    state.currentFight.fighters.splice(index, 1);
  },
  nextRound(state, nextFighters) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    state.currentFight.fighters = nextFighters;
    state.currentFight.currentRound++;
  },
  saveRound(state, round) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    state.currentFight.rounds.push(round);
  },
  changeDotTurns(state, payload) {
    const fighter = getFighter(state, payload.fighterId);
    if (fighter.bleeders === undefined) {
      throw new Error('Bleeders are not initialized');
    }
    if (!(payload.bleederIndex in fighter.bleeders)) {
      throw new Error('Index ' + payload.bleederIndex + 'not in fighter with Id ' + payload.fighterId);
    }
    fighter.bleeders[payload.bleederIndex].turns += payload.value;
  },

  changeDotDamage(state, payload) {
    const fighter = getFighter(state, payload.fighterId);
    if (fighter.bleeders === undefined) {
      throw new Error('Bleeders are not initialized');
    }
    if (!(payload.bleederIndex in fighter.bleeders)) {
      throw new Error('Index ' + payload.bleederIndex + 'not in fighter with Id ' + payload.fighterId);
    }
    fighter.bleeders[payload.bleederIndex].damage += payload.value;
  },

  removeBleeder(state, payload) {
    const fighter = getFighter(state, payload.fighterId);
    if (fighter.bleeders === undefined) {
      throw new Error('Bleeders are not initialized');
    }

    if (!(payload.bleederIndex in fighter.bleeders)) {
      throw new Error('Index ' + payload.bleederIndex + 'not in fighter with Id ' + payload.fighterId);
    }
    fighter.bleeders.splice(payload.bleederIndex, 1);
  },

  updateColor(state, payload) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === payload.fighterId;
    });
    state.currentFight.fighters[index].color = payload.value;
  },

  selectEnemyPlayer(state, payload) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === payload.fighterId;
    });
    state.currentFight.fighters[index].enemyPlayer = payload.value;
  },

  toggleFighterDone(state, fighterId) {
    if (state.currentFight === null) {
      throw new Error('currentFight is null');
    }
    const index = state.currentFight.fighters.findIndex((fighter) => {
      return fighter.id === fighterId;
    });
    if (index === -1) {
      throw new Error('Fighter with id ' + fighterId + ' not found');
    }
    if (!state.currentFight.fighters[index].hasOwnProperty('done')) {
      Vue.set(state.currentFight.fighters[index], 'done', false);
    }
    state.currentFight.fighters[index].done = !state.currentFight.fighters[index].done;
  },
};
