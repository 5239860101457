import { Commit } from 'vuex';
import firebase from 'firebase/app';
import { CharacterExportData } from './character/character';

export default class FirebaseSnapshotListener {
  public static listenOnCharacters(commit: Commit, groupId: string) {
    const unsubscribe = firebase
      .firestore()
      .collection('groups/' + groupId + '/characters')
      .onSnapshot((snapshot) => {
        snapshot.forEach((doc) => {
          const characterData = doc.data();
          if (typeof characterData === 'undefined') {
            throw new Error('Received undefined data for document ' + doc.id);
          }
          (characterData as CharacterExportData).characterId = doc.id;
          commit('addOrUpdateCharacter', characterData);
        });
        FirebaseSnapshotListener.listeners.push(unsubscribe);
      });
  }

  public static unsubscribe() {
    FirebaseSnapshotListener.listeners.forEach((unsubscribe) => {
      unsubscribe();
    });
    FirebaseSnapshotListener.listeners = [];
  }

  private static listeners: Array<() => void> = [];
}
