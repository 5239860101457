


























































































































import Vue from 'vue';
import firebase from 'firebase/app';
import Component from 'vue-class-component';
import { mdiAccount, mdiMail, mdiLock, mdiEye, mdiEyeOff } from '@mdi/js';
import { Prop } from 'vue-property-decorator';

import { CreateUserPayload } from '@/store/payloads';

@Component
export default class LoginForm extends Vue {
  @Prop({ default: true })
  private flatButton!: boolean;

  private username: string = '';
  private email: string = '';
  private password: string = '';
  private dialog: boolean = false;
  private loginOrSignupModel = 0;
  private showPassword: boolean = false;
  private error: string = '';
  private errorCode: string = '';
  private dialogActive: boolean = false;
  private accountIcon = mdiAccount;
  private mailIcon = mdiMail;
  private lockIcon = mdiLock;
  private eyeIcon = mdiEye;
  private eyeOffIcon = mdiEyeOff;
  private createErrorCodes: object = {
    'auth/weak-password': 'Das Passwort ist zu schwach. Bitte gib mehr als 6 Zeichen ein',
    'auth/wrong-password': 'Das eingegebene Passwort ist falsch',
    'auth/email-already-in-use': 'Diese E-Mailadressse wird schon verwendet.',
    'auth/invalid-email': 'Diese E-Mailadresse ist nicht gültig',
    'auth/user-not-found': 'Zu dieser E-Mailadresse wurd kein Konto gefunden',
  };
  private requiredRules: Array<(v) => boolean | string> = [(v) => !!v || 'Username is required'];
  private emailRules: Array<(v) => boolean | string> = [
    (v) => !!v || 'E-mail is required',
    (v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid',
  ];
  private passwordRules: Array<(v) => boolean | string> = [(v) => !!v || 'Password is required'];
  private valid: boolean = true;

  private submitForm(event) {
    event.preventDefault();

    // Login Case
    if (this.loginOrSignupModel === 0) {
      this.doLogin(event).then((model) => {
        if (model !== null) {
          // Submit the form to prompt browser to save credentials
          const form = (this.$refs.form as Vue).$el as HTMLFormElement;
          // Point the form to the dummy iframe
          form.target = 'dummy';
          // submit it
          form.submit();

          this.$store.dispatch('loadUserData').then(() => {
            // Redirect to dashboard if currently on home
            if (this.$route.path === '/') {
              this.$router.push('/home');
            }
          });
        }
      });
    } else if (this.loginOrSignupModel === 1) {
      this.registerClicked();
    }
  }

  private doLogin(event): Promise<null | firebase.User> {
    if (!(this.$refs.form as any).validate()) {
      return Promise.resolve(null);
    }

    // Trigger the form submit event for browsers to check
    event.preventDefault();

    this.dialogActive = true;
    this.error = '';

    const me = this;
    return firebase
      .auth()
      .signInWithEmailAndPassword(me.email, me.password)
      .then((user) => {
        me.dialogActive = false;
        me.email = '';
        me.password = '';
        me.dialog = false;
        me.error = '';

        if (user === null || user.user === null) {
          return null;
        }

        this.$store.commit('setUser', user.user);
        return user.user;
      })
      .catch((error) => {
        console.log(error);
        // Handle Errors here.
        me.errorCode = error.code;
        me.error = error.message;

        me.dialogActive = false;
        return null;
      });
  }

  private resetPasswordClicked() {
    const me = this;
    me.dialogActive = true;
    firebase
      .auth()
      .sendPasswordResetEmail(this.email)
      .then(() => {
        me.dialogActive = false;
      })
      .catch((error) => {
        me.error = error.message;
        me.dialogActive = false;
      });
  }

  private registerClicked() {
    this.dialogActive = true;
    const payload: CreateUserPayload = {
      email: this.email,
      password: this.password,
      username: this.username,
    };
    this.$store
      .dispatch('createUser', payload)
      .then(() => {
        this.dialogActive = false;
        this.dialog = false;
        this.$router.push('/home');
      })
      .catch((error) => {
        this.dialogActive = false;

        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;

        console.log(errorCode, errorMessage);

        // Check if we have a message for this error
        if (this.createErrorCodes.hasOwnProperty(errorCode)) {
          this.error = this.createErrorCodes[errorCode];
        } else {
          this.error = errorMessage;
        }

        throw new Error(error.errorMessage);
        this.dialogActive = false;
      });
  }
}
