import { ActionTree } from 'vuex';
import { FightState } from './types';
import { RootState } from '../types';
import firebase from 'firebase/app';
import players from '@/constants/players';
import 'firebase/firestore';
import FirebaseGateway from '@/classes/firebaseGateway';
import Fighter from '@/interfaces/Fighter';

export const actions: ActionTree<FightState, RootState> = {
  setCurrentFight({ commit, dispatch, rootState }, fight): any {
    // If this fight is not yet part of the users fights, add it
    if (rootState.userData !== null && !rootState.userData.fights.includes(fight.id)) {
      commit('addFightToUser', fight.id);
      dispatch('saveFightsToFirebase');
    }

    commit('setCurrentFight', fight);
  },
  saveFightsToFirebase({ state, rootState, commit }) {
    if (rootState.user === null) {
      throw new Error('saveFightsToFirebase but user is null');
    }
    const userId = rootState.user.uid;
    const fightIds = state.fights.map((fight) => fight.id);
    firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .set({ fights: fightIds }, { merge: true })
      .then(() => {
        commit('addMessage', 'Speichern erfolgreich');
      })
      .catch((error) => {
        commit('addMessage', 'Fehler beim Speichern: ' + error);
      });
  },
  deleteFightFromFirebase({ commit }, payload) {
    commit('setSaving', true);
    return firebase
      .firestore()
      .doc('fights/' + payload)
      .delete()
      .then(() => {
        commit('setSaving', false);
        commit('addMessage', 'Kampf ' + payload + ' gelöscht');
      })
      .catch((error) => {
        commit('addMessage', 'Fehler beim Löschen: ' + error);
      });
  },
  leaveFight({ commit, dispatch, rootState }, fightId: string) {
    if (rootState.userData === null || !rootState.userData.fights.includes(fightId)) {
      return;
    }
    commit('removeFight', fightId);
    dispatch('saveFightsToFirebase');
  },
  /**
   *
   */
  createFight({ rootState, commit }) {
    if (rootState.user === null) {
      throw new Error('createFight: user is null');
    }

    const uid = rootState.user.uid;
    const promise = new Promise<string>((resolve) => {
      firebase
        .firestore()
        .collection('fights')
        .add({
          owner: uid,
          users: [uid],
          players,
          fighters: [],
          currentRound: 1,
          created: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((docRef) => {
          resolve(docRef.id);
          commit('addMessage', 'Kampf mit ID ' + docRef.id + ' erstellt');
        });
    });

    return promise;
  },
  /**
   * Deletes the fight with the passed id
   * @param param0
   * @param fightId
   */
  deleteFight({ commit, dispatch }, fightId) {
    commit('removeFight', fightId);
    return dispatch('deleteFightFromFirebase', fightId).then(() => {
      dispatch('saveFightsToFirebase');
    });
  },
  /**
   * Loads the fights of the logged in user.
   * Returns a promise that is resolved once the user is logged in and the fights
   * are returned from firestore.
   * @param param0
   * @returns Promise<Fight[]>
   */
  loadFights({ commit, dispatch }) {
    const loadFightsPromise = FirebaseGateway.loadFights();

    // Additionally load all presets
    dispatch('loadFighterPresets');

    // Save all returned fights
    loadFightsPromise.then((fights) => {
      // First clean the store
      commit('cleanFights');

      fights.sort((a, b) => {
        let bVal = 0;
        if (b.hasOwnProperty('created') && typeof b.created !== 'undefined' && b.created !== null) {
          bVal = b.created.seconds;
        }
        let aVal = 0;
        if (a.hasOwnProperty('created') && typeof a.created !== 'undefined' && a.created !== null) {
          aVal = a.created.seconds;
        }
        return bVal - aVal;
      });
      fights.forEach((fight) => {
        commit('addFight', fight);
      });
    });

    return loadFightsPromise;
  },
  loadFighterPresets({ commit, rootState, rootGetters }) {
    if (!rootState.user) {
      throw new Error('User not set');
    }
    const allPromises: Array<Promise<any>> = [];
    const loadPresetsPromise = FirebaseGateway.loadFighterPresets(rootState.user.uid);
    loadPresetsPromise.then((fighterPresets) => {
      commit('setFighterPresets', fighterPresets);
    });
    allPromises.push(loadPresetsPromise);
    // Additionally, load beasts if we are an admin
    if (rootGetters.isGroupMaster) {
      if (rootGetters.currentGroup === null) {
        throw new Error('currentGroup is null');
      }
      const beastsPromise = FirebaseGateway.loadBeasts(rootGetters.currentGroup, false).then((beasts) => {
        commit('setBeasts', beasts);
        return beasts;
      });
      allPromises.push(beastsPromise);
    }
    return Promise.all(allPromises);
  },
  addFighterToCurrentFight({ commit, dispatch }, fighter) {
    commit('addFighter', fighter);
    dispatch('saveCurrentFightToFirebase');
  },
  duplicateFighter({ commit, dispatch }, fighterId) {
    commit('duplicateFighter', fighterId);
    dispatch('saveCurrentFightToFirebase');
  },
  toggleFighterActive({ commit, dispatch }, fighterId) {
    commit('toggleFighterActive', fighterId);
    dispatch('saveCurrentFightToFirebase');
  },
  killFighter({ commit, dispatch }, fighterId) {
    commit('killFighter', fighterId);
    dispatch('saveCurrentFightToFirebase');
  },
  removeFighter({ commit, dispatch }, fighterId) {
    commit('removeFighter', fighterId);
    dispatch('saveCurrentFightToFirebase');
  },
  changeNotes({ commit, dispatch }, payload) {
    commit({
      type: 'changeNotes',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  sortFighters({ state, dispatch }) {
    if (state.currentFight === null) {
      throw new Error('sortFighters: currentFight is null');
    }
    state.currentFight.fighters.sort((a, b) => {
      // Special case: Sort inactive fighters to the back
      if (a.inactive) {
        return 1;
      } else if (b.inactive) {
        return -1;
      }
      // In case sequence value is identical, use initiative
      const initialDifference = b.sequenceValue - a.sequenceValue;
      if (initialDifference === 0) {
        return b.type === 'enemy' ? 1 : -1;
      }
      return initialDifference;
    });
    dispatch('saveCurrentFightToFirebase');
  },
  updateInitiative({ commit, dispatch }, payload) {
    commit({
      type: 'updateInitiative',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  updateSequenceValue({ commit, dispatch }, payload) {
    commit({
      type: 'updateSequenceValue',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  changeLep({ commit, dispatch }, payload) {
    commit({
      type: 'changeLep',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  changeSlots({ commit, dispatch }, payload) {
    commit({
      type: 'changeSlots',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  addWound({ commit, dispatch }, payload) {
    commit({
      type: 'addWound',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  healWound({ commit, dispatch }, payload) {
    commit({
      type: 'healWound',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  addDot({ commit, dispatch }, payload) {
    commit({ type: 'addDot', fighterId: payload.fighterId });
    dispatch('saveCurrentFightToFirebase');
  },
  changeDotTurns({ commit, dispatch }, payload) {
    commit({
      type: 'changeDotTurns',
      fighterId: payload.fighterId,
      bleederIndex: payload.bleederIndex,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  changeDotDamage({ commit, dispatch }, payload) {
    commit({
      type: 'changeDotDamage',
      fighterId: payload.fighterId,
      bleederIndex: payload.bleederIndex,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  removeBleeder({ commit, dispatch }, payload) {
    commit({
      type: 'removeBleeder',
      fighterId: payload.fighterId,
      bleederIndex: payload.bleederIndex,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  selectColor({ commit, dispatch }, payload) {
    commit({
      type: 'updateColor',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  selectEnemyPlayer({ commit, dispatch }, payload) {
    commit({
      type: 'selectEnemyPlayer',
      fighterId: payload.fighterId,
      value: payload.value,
    });
    dispatch('saveCurrentFightToFirebase');
  },
  toggleFighterDone({ commit, dispatch }, fighterId) {
    commit('toggleFighterDone', fighterId);
    dispatch('saveCurrentFightToFirebase');
  },
  setAllFightersNotDone({ commit, dispatch }, fighterIds: string[]) {
    for (const fighterId of fighterIds) {
      commit('toggleFighterDone', fighterId);
    }
    dispatch('saveCurrentFightToFirebase');
  },
  addPlayer({ commit, dispatch }, player) {
    commit('addFighter', player);
    dispatch('saveCurrentFightToFirebase');
  },
  nextRound({ commit, dispatch }, nextFighters) {
    commit('nextRound', nextFighters);
    return dispatch('saveCurrentFightToFirebase');
  },
  addOrUpdateFightPreset({ rootState, commit }, preset) {
    if (!rootState.user) {
      throw new Error('User not set');
    }
    const firebaseGateway = new FirebaseGateway();
    return firebaseGateway.addOrUpdateFightPreset(rootState.user.uid, preset).then(() => {
      commit('setFighterPreset', preset);
    });
  },
  deletePreset({ rootState, dispatch }, presetId) {
    if (!rootState.user) {
      throw new Error('User not set');
    }
    return FirebaseGateway.removeFighterPreset(rootState.user.uid, presetId).then(() => {
      return dispatch('loadFighterPresets');
    });
  },
  saveCurrentFightToFirebase({ commit, state, rootState }) {
    if (rootState.user === null) {
      throw new Error('saveCurrentFightToFirebase: user is null');
    }
    if (state.currentFight === null) {
      throw new Error('saveCurrentFightToFirebase: currentFight is null');
    }

    commit('setSaving', true);
    // Make copy of the array to prevent changing original fight
    const currentFight = JSON.parse(JSON.stringify(state.currentFight));

    // transform the rounds into multiple fields, because nested arrays
    // are not support in firebase
    const rounds = currentFight.rounds;
    rounds.forEach((round, index) => {
      const roundName = 'round_' + index;
      currentFight[roundName] = round;
    });
    delete currentFight.rounds;

    const firebaseGateway = new FirebaseGateway();

    if (state.currentFight.id == null) {
      throw new Error('currentFight is empty');
    } else {
      firebaseGateway.saveFight(currentFight).then(() => {
        commit('setSaving', false);
        commit('addMessage', 'Kampf gespeichert');
      });
    }

    // Save the currentfight to the user
    firebaseGateway.setCurrentFightForUser(rootState.user.uid, state.currentFight.id);
  },
};
