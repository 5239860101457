import Fight from '@/interfaces/Fight';
import Fighter from '@/interfaces/Fighter';
import { Person } from '@/interfaces/Person';
import { CharacterExportData } from './character/character';
import FirebaseGateway from './firebaseGateway';
import { ClassCardData } from '@/interfaces/character/classCard';
import { Beast } from '@/interfaces/world/beast';

export default class Migrator {
  public static migratePerson(person: Person) {
    if (!person.hasOwnProperty('source')) {
      person.source = 'world';
    }
    return person;
  }

  public static migrateBeastToFighter(beast: Beast): Fighter | null {
    const beastValueKeys = beast.values.map((beastValue) => beastValue.name.toLowerCase());

    return null;
  }

  public static async migrateCharacter(
    character: CharacterExportData,
    worldId: string,
    groupId: string,
  ): Promise<CharacterExportData> {
    if (typeof character.activeClasscards !== 'undefined' && Array.isArray(character.activeClasscards)) {
      // In case the old system with classcardIds and string "empty" is present
      character.activeClasscards = character.activeClasscards.map((classCard) => {
        if (typeof classCard === 'string' && classCard === 'empty') {
          return null;
        }
        return classCard;
      });
    }
    if (typeof character.activeClasscards === 'undefined') {
      character.activeClasscards = [];
    }
    if (typeof character.classcards === 'undefined') {
      character.classcards = [];
    }

    let updateCharacter = false;

    for (const parameter of ['activeClasscards', 'classcards']) {
      if (
        typeof character[parameter] !== 'undefined' &&
        character[parameter]
          .map((card) => typeof card === 'string')
          .reduce((hasString, isString) => hasString || isString, false)
      ) {
        // Collect all cardIds which need to be loaded
        const cardIds: string[] = (character[parameter].filter(
          (card) => typeof card === 'string',
        ) as unknown) as string[];

        // We need to update the migrated character afterwards
        updateCharacter = true;

        // Load them from firebase
        const cards = await FirebaseGateway.loadClassCardsByIds(worldId, cardIds);
        cards.forEach((classCard) => {
          if (typeof character[parameter] === 'undefined') {
            throw new Error('Activecards became undefined??');
          }
          const classCardData = classCard.data() as ClassCardData;
          classCardData.id = classCard.id;
          // Find the index of the cards id and swap it with the data
          const index = character[parameter].findIndex((existingCard) => {
            return typeof existingCard === 'string' && existingCard === classCard.id;
          });
          if (typeof index === 'undefined') {
            throw new Error('Could not find activeClasscard ' + classCard.id);
          }
          character[parameter][index] = classCardData;
        });
      }

      if (updateCharacter) {
        if (character.characterId === null) {
          throw new Error('CharacterId is not supposed to be null');
        }
        FirebaseGateway.updateActiveClassCards(groupId, character.characterId, character.activeClasscards);
        FirebaseGateway.updateCharacterClassCards(groupId, character.characterId, character.classcards);
      }
    }

    return character;
  }
}
