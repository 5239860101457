

































import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/database';
import 'firebase/auth';
import navbar from './components/navbar.vue';
import navdrawer from './components/navdrawer.vue';
import Friend from '@/interfaces/Friend';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import FirebaseGateway from './classes/firebaseGateway';
import { Getter } from 'vuex-class';
import { PnPTheme } from './interfaces/settings/theme';

@Component({
  components: { navbar, navdrawer },
})
export default class App extends Vue {
  private friends: Friend[] = [];
  private showLoginDialog: boolean = false;
  private showMessage: boolean = false;
  private currentMessage: { id: string; message: string } = { id: 'temp', message: '' };
  private navdrawerOpen = false;
  private newVersion: string | null = null;

  @Getter
  private theme!: PnPTheme;

  constructor() {
    super();
  }

  public created() {
    let onSnapshotCancel = () => {
      return;
    };
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.dispatch('setUser', JSON.parse(JSON.stringify(user)));
      if (user != null) {
        // Load any data assigned to this user
        this.$store.dispatch('loadUserData');

        const currentUser = firebase.auth().currentUser;
        if (currentUser === null) {
          return;
        }

        // Fetch the current user's ID from Firebase Authentication.
        const uid = currentUser.uid;

        // Create a reference to this user's specific status node.
        // This is where we will store data about being online/offline.
        const userStatusDatabaseRef = firebase.database().ref('/status/' + uid);

        // We'll create two constants which we will write to
        // the Realtime database when this device is offline
        // or online.
        const isOfflineForDatabase = {
          state: 'offline',
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        const isOnlineForDatabase = {
          state: 'online',
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        // Create a reference to the special '.info/connected' path in
        // Realtime Database. This path returns `true` when connected
        // and `false` when disconnected.
        firebase
          .database()
          .ref('.info/connected')
          .on('value', (snapshot) => {
            // If we're not currently connected, don't do anything.
            if (snapshot === null || snapshot.val() === false) {
              return;
            }

            // If we are currently connected, then use the 'onDisconnect()'
            // method to add a set which will only trigger once this
            // client has disconnected by closing the app,
            // losing internet, or any other means.
            userStatusDatabaseRef
              .onDisconnect()
              .set(isOfflineForDatabase)
              .then(() => {
                // The promise returned from .onDisconnect().set() will
                // resolve as soon as the server acknowledges the onDisconnect()
                // request, NOT once we've actually disconnected:
                // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

                // We can now safely set ourselves as 'online' knowing that the
                // server will mark us as offline once we lose connection.
                userStatusDatabaseRef.set(isOnlineForDatabase);
              });
          });

        onSnapshotCancel = firebase
          .firestore()
          .collection('users/' + uid + '/friends')
          .onSnapshot(
            (snap) => {
              snap.docChanges().forEach((change) => {
                if (change.type === 'added') {
                  const friend = change.doc.data();
                  friend.uid = change.doc.id;
                  this.friends.push(friend as Friend);
                } else if (change.type === 'removed') {
                  this.friends.forEach((friend, index) => {
                    if (friend.uid === change.doc.id) {
                      this.friends.splice(index, 1);
                    }
                  });
                } else if (change.type === 'modified') {
                  this.friends.forEach((friend, index) => {
                    if (friend.uid === change.doc.id) {
                      const friendLocal = change.doc.data();
                      friendLocal.uid = change.doc.id;
                      Vue.set(this.friends, index, friendLocal);
                    }
                  });
                }
              });
            },
            (error) => {
              console.log('error in snapshot');
              console.log(error);
            },
          );

        // Signout, do cleanup
      } else {
        onSnapshotCancel();
        this.friends = [];
      }
    });
  }

  public mounted() {
    this.checkForNewerVersion();

    // Periodically check every minute
    setInterval(() => {
      this.checkForNewerVersion();
    }, 30 * 1000);
  }

  @Watch('messages')
  private onMessageChanged(newValue: Array<{ id: string; message: string }>) {
    if (newValue.length > 0) {
      const currentMessage = newValue[0];
      if (this.currentMessage === null || this.currentMessage.id !== currentMessage.id) {
        this.currentMessage = currentMessage;
        this.showMessage = true;
        this.triggerMessageRemoval();
      }
    }
  }

  @Watch('theme', { deep: true })
  private onThemeChanged(newTheme: PnPTheme) {
    this.$vuetify.theme.themes.dark.primary = newTheme.colors.primary;
    this.$vuetify.theme.themes.dark.secondary = newTheme.colors.secondary;
    this.$vuetify.theme.themes.dark.accent = newTheme.colors.accent;
  }

  private triggerMessageRemoval() {
    const me = this;
    me.showMessage = true;
    setTimeout(() => {
      me.$store.commit('removeLastMessage');
    }, 3500);
  }

  get messages(): object[] {
    return this.$store.getters.getMessages;
  }

  private checkForNewerVersion() {
    FirebaseGateway.getVersionFromRealtime().then((version) => {
      if (typeof version !== 'undefined') {
        const currentVersion = this.$store.getters.version;

        // Check whether we got a more recent version from the API
        const versionPartsApi = version.split('.');
        const versionPartsCurrent = currentVersion.split('.');
        const newerMajor = parseInt(versionPartsCurrent[0], 10) < parseInt(versionPartsApi[0], 10);
        const newerMinor = parseInt(versionPartsCurrent[1], 10) < parseInt(versionPartsApi[1], 10);
        const newerPatch = parseInt(versionPartsCurrent[2], 10) < parseInt(versionPartsApi[2], 10);

        if (newerMajor || newerMinor || newerPatch) {
          this.newVersion = version;
        }
      } else {
        throw new Error('Version not found in reponse');
      }
    });
  }

  private reload() {
    window.location.reload();
  }
}
