import { GetterTree } from 'vuex';
import { RootState } from './types';
import { PnPTheme } from '@/interfaces/settings/theme';

export const getters: GetterTree<RootState, any> = {
  getUser(state) {
    return state.user;
  },

  userId(state) {
    return state.user === null ? null : state.user.uid;
  },

  userData(state) {
    return state.userData;
  },

  isSaving(state) {
    return state.isSaving;
  },

  getMessages(state) {
    return state.messages;
  },

  settings(state) {
    return state.settings;
  },

  groups(state) {
    return state.groups;
  },

  currentGroup(state) {
    return state.userData?.currentGroup;
  },

  currentGroupData(state) {
    return state.currentGroupData;
  },

  isGroupMaster(state) {
    if (state.user && state.currentGroupData && Array.isArray(state.currentGroupData.masters)) {
      const userId = state.user.uid;
      if (state.currentGroupData.masters.includes(userId)) {
        return true;
      }
    }
    return false;
  },

  backgroundImage(state) {
    return state.theme.background;
  },

  backgroundOpacity(state) {
    return state.theme.opacity;
  },

  theme(state): PnPTheme {
    return state.theme;
  },

  version(state) {
    return state.version;
  },

  userLog(state) {
    return state.userLog;
  },
};
