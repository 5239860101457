






































































































































































































import Vue from 'vue';
import { State, Action, Getter } from 'vuex-class';
import availableIcons from '../../constants/icons';
import availableColors from '../../constants/colors';
import Fight from '@/interfaces/Fight';
import Fighter from '@/interfaces/Fighter';
import Bleeder from '@/interfaces/Bleeder';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class FighterComponent extends Vue {
  get playersInFight(): Fighter[] {
    const currentFight = this.currentFight;
    const activePlayers: Fighter[] = [];
    currentFight.fighters.forEach((element) => {
      if (element.type === 'player') {
        activePlayers.push(element);
      }
    });

    return activePlayers;
  }

  @Getter
  public currentFight!: Fight;
  @Prop()
  private id!: string;
  @Prop()
  private lep!: number;
  @Prop()
  private initiative!: number;
  @Prop()
  private sequenceValue!: number;
  @Prop()
  private bleeders!: Bleeder[];
  @Prop()
  private es!: number;
  @Prop()
  private slots!: number;
  @Prop()
  private wounds!: number;
  @Prop()
  private name!: string;
  @Prop()
  private type!: string;
  @Prop()
  private alive!: boolean;
  @Prop()
  private enemyPlayer!: string;
  @Prop()
  private inactive!: boolean;
  @Prop()
  private color!: string;
  @Prop()
  private icon!: string;
  @Prop()
  private isHover!: boolean;
  @Prop()
  private hoveredFighter!: number;
  @Prop()
  private notes!: string;
  @Prop({ default: false })
  private isCurrentRound!: boolean;

  private showColorSelection: boolean = false;
  private showSettings: boolean = false;
  private availableColors: string[] = availableColors;
  private availableIcons: string[] = availableIcons;

  get fighterData(): Fighter {
    return {
      id: this.id,
      name: this.name,
      type: this.type,
      sequenceValue: this.sequenceValue,
      lep: this.lep,
      color: this.color,
      icon: this.icon,
    };
  }

  public changeInitiative(value) {
    this.$store.dispatch({
      type: 'updateInitiative',
      fighterId: this.id,
      value: parseInt(value, 10),
    });
  }

  public changeSequenceValue(value) {
    this.$store.dispatch({
      type: 'updateSequenceValue',
      fighterId: this.id,
      value: parseInt(value, 10),
    });
  }

  public changeLep(value) {
    this.$store.dispatch({
      type: 'changeLep',
      fighterId: this.id,
      value: parseInt(value, 10),
    });
  }

  public emitChange(type: string, value: string) {
    this.$store.dispatch({
      type,
      fighterId: this.id,
      value: parseInt(value, 10),
    });
  }

  public toggleAlive() {
    this.$store.dispatch({
      type: 'setFighterAlive',
      fighterId: this.id,
    });
  }

  public addWound() {
    if (!this.inactive) {
      this.$store.dispatch({
        type: 'addWound',
        fighterId: this.id,
      });
    }
  }

  public healWound(e) {
    e.preventDefault();
    if (!this.inactive && this.wounds > 0) {
      this.$store.dispatch({
        type: 'healWound',
        fighterId: this.id,
      });
    }
  }

  public addDot() {
    if (!this.inactive) {
      this.$store.dispatch({
        type: 'addDot',
        fighterId: this.id,
      });
    }
  }

  public increaseDotTurns(bleederIndex) {
    if (!this.inactive) {
      this.$store.dispatch({
        type: 'changeDotTurns',
        fighterId: this.id,
        bleederIndex,
        value: 1,
      });
    }
  }

  public increaseDotDamage(bleederIndex) {
    if (!this.inactive) {
      this.$store.dispatch({
        type: 'changeDotDamage',
        fighterId: this.id,
        bleederIndex,
        value: 1,
      });
    }
  }

  public decreaseDotTurns(bleederIndex, event) {
    event.preventDefault();
    if (!this.inactive) {
      this.$store.dispatch({
        type: 'changeDotTurns',
        fighterId: this.id,
        bleederIndex,
        value: -1,
      });
    }
  }

  public decreaseDotDamage(bleederIndex, event) {
    event.preventDefault();
    if (!this.inactive) {
      this.$store.dispatch({
        type: 'changeDotDamage',
        fighterId: this.id,
        bleederIndex,
        value: -1,
      });
    }
  }

  public clearBleeder(bleederIndex) {
    if (!this.inactive) {
      this.$store.dispatch({
        type: 'removeBleeder',
        fighterId: this.id,
        bleederIndex,
      });
    }
  }

  public selectColor(value) {
    this.$store.dispatch({
      type: 'selectColor',
      fighterId: this.id,
      value,
    });
  }

  public selectIcon(value) {
    this.$store.dispatch({
      type: 'selectIcon',
      fighterId: this.id,
      value,
    });
  }

  public toggleActivityStatus() {
    this.$store.dispatch('toggleFighterActive', this.id);
  }

  public duplicateFighter() {
    this.$store.dispatch('duplicateFighter', this.id);
  }

  public saveAsPreset() {
    this.$store.dispatch('addOrUpdateFightPreset', this.fighterData).then(() => {
      this.$store.commit('addMessage', 'Vorlage erfolgreich angelegt: ' + this.fighterData.name);
    });
  }

  public killFighter() {
    this.$store.dispatch('killFighter', this.id);
  }

  public removeFighter() {
    this.$store.dispatch('removeFighter', this.id);
  }

  public hover(isHover) {
    this.$emit('fighterhover', this.id, isHover);
  }

  public changeNotes(notes) {
    this.$store.dispatch({
      type: 'changeNotes',
      fighterId: this.id,
      value: notes,
    });
  }

  public selectEnemyPlayer(player) {
    const selected = player !== null ? player.short : player;
    this.$store.dispatch({
      type: 'selectEnemyPlayer',
      fighterId: this.id,
      value: selected,
    });
  }
}
