import VueI18n from 'vue-i18n';
import Vue from 'vue';

const messages = {
  en: {
    home: {
      claim: 'Supporting you P&amp;P life',
    },
  },
  de: {
    home: {
      claim: 'Alles für deine Spielrunde',
    },
  },
};
Vue.use(VueI18n);
const i18n = new VueI18n({
  locale: 'de',
  messages,
});

export default i18n;
