import firebase from 'firebase/app';
import 'firebase/storage';
import { Person } from '@/interfaces/Person';

export default class FirebaseStorageLoader {
  /**
   *
   * @param groupId The Id of the group the image is associated with
   * @param person The person the image should be loaded for
   */
  public static async fetchPersonImageUrl(groupId: string, person: Person): Promise<string> {
    if (person.image === null) {
      throw new Error('Image of Person is empty');
    }
    // Create a reference with an initial file path and name
    const storage = firebase.storage();
    const pathReference = storage.ref('groups/' + groupId + '/' + person.image);
    try {
      const groupUrl = await pathReference.getDownloadURL();
      return groupUrl;
    } catch (err) {
      console.log('image not found at group');
      try {
        const worldUrl = await storage.ref('worlds/pathfinder-2e/persons/' + person.image).getDownloadURL();
        return worldUrl;
      } catch {
        throw new Error('Unable to load storage url for ' + person.image);
      }
    }
  }

  public static async fetchHandoutUrl(groupId: string, handoutName: string): Promise<string> {
    // Create a reference with an initial file path and name
    const storage = firebase.storage();
    const pathReference = storage.ref('groups/' + groupId + '/handouts/' + handoutName);

    return await pathReference.getDownloadURL();
  }

  public static async fetchLibraryUrl(worldSlug: string, filename: string): Promise<string> {
    // Create a reference with an initial file path and name
    const storage = firebase.storage();
    const pathReference = storage.ref('worlds/' + worldSlug + '/library/' + filename);

    return await pathReference.getDownloadURL();
  }

  public static async fetchPersonalLibraryUrl(userId: string, filename: string): Promise<string> {
    // Create a reference with an initial file path and name
    const storage = firebase.storage();
    const pathReference = storage.ref('user/' + userId + '/library/' + filename);

    return await pathReference.getDownloadURL();
  }
}
