import { ActionTree } from 'vuex';
import { WorldState } from './types';
import { RootState } from '../types';
import FirebaseGateway from '@/classes/firebaseGateway';
import { WorldMarker } from '@/interfaces/Marker';
import { Person } from '@/interfaces/Person';
import { World } from '@/interfaces/world/world';
import { AudioLibraryItem, LibraryItem } from '@/interfaces/world/library';
import { ClassCardData } from '@/interfaces/character/classCard';
import { Beast } from '@/interfaces/world/beast';
import firebase from 'firebase/app';
import 'firebase/firestore';

export const actions: ActionTree<WorldState, RootState> = {
  /**
   * @param param0
   * @param marker
   */
  addMarker({ commit, state }, marker: WorldMarker): Promise<string> {
    if (state.currentWorld === null) {
      throw new Error('CurrentWorld is empty');
    }

    const promise = new FirebaseGateway().saveMarker(state.currentWorld, marker);
    promise.then((markerId) => {
      // Set the id and save to state
      marker.id = markerId;
      commit('addMarker', marker);
      return marker.id;
    });
    return promise;
  },

  async loadPersons({ commit, dispatch, rootState }, groupId) {
    if (groupId === null) {
      throw new Error('No group selected');
    }
    if (rootState.user === null) {
      throw new Error('User is null');
    }

    const firebasePersons = await new FirebaseGateway().getPublicPersons(groupId);
    const firebasePrivatePersons = await new FirebaseGateway().getPrivatePersons(groupId, rootState.user.uid);

    const allPersons: Person[] = [];
    const processFirebaseResult = (querySnapshot: firebase.firestore.QuerySnapshot) => {
      querySnapshot.forEach((doc) => {
        // incorporate the id of the document into the object
        const person = doc.data() as Person;
        person.id = doc.id;

        // Fetch the image url if it hasn't been saved
        if (typeof person.image !== 'undefined' && person.image !== null && !person.loadedImageUrl) {
          dispatch('loadImageUrl', person);
        }
        commit('addPerson', person);
        allPersons.push(person);
      });
    };

    processFirebaseResult(firebasePersons);
    processFirebaseResult(firebasePrivatePersons);

    return Promise.resolve(allPersons);
  },

  async getPersonsByLocation({ state, dispatch, rootState }, locationId) {
    let personsOfLocation: Person[] = [];
    if (state.persons.length === 0) {
      personsOfLocation = await dispatch('loadPersons', rootState.currentGroup);
    } else {
      personsOfLocation = state.persons;
    }

    personsOfLocation = personsOfLocation.filter((person) => person.location === locationId);
    return Promise.resolve(personsOfLocation);
  },

  /**
   * Loads the map markers
   */
  loadWorldMapMarkers({ commit, state }) {
    if (state.markers.length > 0) {
      return Promise.resolve(true);
    }

    if (state.currentWorld === null) {
      throw new Error('loadWorldMapMarkers: CurrentWorld is null');
    }

    const loadWorldPromise = new FirebaseGateway().loadMapMarkers(state.currentWorld);
    loadWorldPromise.then((mapMarkers) => {
      mapMarkers.forEach((markerDocument) => {
        const marker = markerDocument.data();
        marker.id = markerDocument.id;
        commit('addMarker', marker);
      });
    });
    return loadWorldPromise;
  },

  loadCurrentWorld({ commit, state }) {
    if (state.currentWorld === null) {
      throw new Error('currentWorld is null');
    }
    FirebaseGateway.getWorld(state.currentWorld).then((worldData) => {
      if (typeof worldData === 'undefined') {
        throw new Error('Could not load currentWorld: ' + state.currentWorld);
      }
      commit('saveAvailableWorld', worldData);
    });
  },

  loadWorlds({ commit, rootState }) {
    let userId: string | null = null;
    if (rootState.user !== null) {
      userId = rootState.user.uid;
    }
    const worldsPromise = new FirebaseGateway().getWorlds(userId);

    worldsPromise.then((worldSnapshots) => {
      worldSnapshots.forEach((worldSnapshot) => {
        worldSnapshot.docs.forEach((worldDoc) => {
          const worldData: World = worldDoc.data() as World;
          worldData.slug = worldDoc.id;
          commit('saveAvailableWorld', worldData);
        });
      });
    });

    return worldsPromise;
  },

  createLibraryItem({ commit, state }, libraryItem: LibraryItem) {
    if (state.currentWorld === null) {
      throw new Error('current world is null, cannot upload library item');
    }
    return FirebaseGateway.uploadLibraryItem(state.currentWorld, libraryItem).then((itemId) => {
      libraryItem.id = itemId;
      commit('setLibraryItem', libraryItem);
      return true;
    });
  },

  deleteLibraryItem({ commit, state }, libraryItem: LibraryItem) {
    if (state.currentWorld === null) {
      throw new Error('current world is null, cannot delete library item');
    }
    return FirebaseGateway.removeLibraryItem(state.currentWorld, libraryItem).then(() => {
      commit('removeLibraryItem', libraryItem);
      return true;
    });
  },

  loadLibrary({ commit, state, rootGetters }) {
    if (state.currentWorld === null) {
      throw new Error('current world is null, cannot load library');
    }

    FirebaseGateway.loadLibrary(state.currentWorld, rootGetters.userId).then((library) => {
      library.forEach((item) => {
        commit('setLibraryItem', item);
      });
    });
  },

  createAudioLibraryItem({ commit, state }, libraryItem: AudioLibraryItem) {
    if (state.currentWorld === null) {
      throw new Error('current world is null, cannot upload library item');
    }
    return FirebaseGateway.uploadAudioLibraryItem(state.currentWorld, libraryItem).then((itemId) => {
      libraryItem.id = itemId;
      commit('setAudioLibraryItem', libraryItem);
      return true;
    });
  },

  loadAudioLibrary({ commit, state, rootGetters }) {
    if (state.currentWorld === null) {
      throw new Error('current world is null, cannot load library');
    }

    FirebaseGateway.loadAudioLibrary(state.currentWorld).then((library) => {
      library.forEach((item) => {
        commit('setAudioLibraryItem', item);
      });
    });
  },

  loadClassCards({ commit, state }, onlyClass: string | null = null) {
    if (state.currentWorld === null) {
      throw new Error('currentworld is null');
    }
    // Check if we have already loaded the cards for this class
    if (onlyClass !== null) {
      const foundIndex = state.classCards.findIndex((classCard) => classCard.classValue === onlyClass);
      if (foundIndex > -1) {
        return true;
      }
    }
    return FirebaseGateway.loadClassCards(state.currentWorld, onlyClass).then((classCards) => {
      classCards.forEach((classCard) => {
        const classCardData = classCard.data() as ClassCardData;
        classCardData.id = classCard.id;
        commit('setClassCard', classCardData);
      });
    });
  },

  uploadClassCard({ commit, state }, classCardData: ClassCardData) {
    if (state.currentWorld === null) {
      throw new Error('currentworld is null');
    }
    return FirebaseGateway.uploadClassCard(state.currentWorld, classCardData).then((returnedData) => {
      commit('setClassCard', returnedData);
    });
  },

  /**
   * BESTIARY
   */
  loadBeasts({ commit, rootState }, isGroupView = null) {
    if (rootState.currentGroup === null) {
      throw new Error('currentGroup is null');
    }
    return FirebaseGateway.loadBeasts(rootState.currentGroup, isGroupView).then((beasts) => {
      commit('setBeasts', beasts);
      return true;
    });
  },

  createBeast({ state, commit }, beast: Beast) {
    if (state.currentWorld === null) {
      throw new Error('currentworld is null');
    }
    return FirebaseGateway.createBeast(state.currentWorld, beast).then((createdBeast) => {
      commit('addBeast', createdBeast);
      return true;
    });
  },
};
