import { MutationTree } from 'vuex';
import { WorldState } from './types';
import { WorldMarker } from '@/interfaces/Marker';
import Vue from 'vue';
import Migrator from '@/classes/migrator';
import { World } from '@/interfaces/world/world';
import { AudioLibraryItem, LibraryItem } from '@/interfaces/world/library';
import { ClassCardData } from '@/interfaces/character/classCard';
import { Beast } from '@/interfaces/world/beast';

export const mutations: MutationTree<WorldState> = {
  setCurrentWorld(state: WorldState, world: string) {
    state.currentWorld = world;
  },

  saveAvailableWorld(state, world: World) {
    const index = state.availableWorlds.findIndex((existingWorld) => existingWorld.slug === world.slug);
    if (index < 0) {
      state.availableWorlds.push(world);
    } else {
      Vue.set(state.availableWorlds, index, world);
    }
  },

  /**
   * Sets the passed fight as the current fight of the user
   * @param state
   * @param currentFight
   */

  addMarker(state, marker: WorldMarker) {
    state.markers.push(marker);
  },

  setPersons(state, persons) {
    state.persons = persons;
  },

  addPerson(state, payload) {
    payload = Migrator.migratePerson(payload);

    const index = state.persons.findIndex((person) => {
      return person.id === payload.id;
    });

    if (!payload.hasOwnProperty('importance')) {
      payload.importance = 1;
    }

    if (index >= 0) {
      Vue.set(state.persons, index, payload);
    } else {
      state.persons.push(payload);
    }
  },

  deletePerson(state, id) {
    const index = state.persons.findIndex((person) => {
      return person.id === id;
    });
    if (index >= 0) {
      state.persons.splice(index, 1);
    }
  },

  addAvailableWorld(state, world: World) {
    const index = state.availableWorlds.findIndex((existingWorld) => {
      return existingWorld.slug === world.slug;
    });

    if (index >= 0) {
      Vue.set(state.availableWorlds, index, world);
    } else {
      state.availableWorlds.push(world);
    }
  },

  setLibraryItem(state, libraryItem: LibraryItem) {
    const index = state.library.findIndex((localItem) => {
      return localItem.id === libraryItem.id;
    });

    if (index >= 0) {
      Vue.set(state.library, index, libraryItem);
    } else {
      state.library.push(libraryItem);
    }
  },

  setAudioLibraryItem(state, libraryItem: AudioLibraryItem) {
    const index = state.audiothek.findIndex((localItem) => {
      return localItem.id === libraryItem.id;
    });

    if (index >= 0) {
      Vue.set(state.library, index, libraryItem);
    } else {
      state.audiothek.push(libraryItem);
    }
  },

  removeLibraryItem(state, libraryItem: LibraryItem) {
    const index = state.library.findIndex((localItem) => {
      return localItem.id === libraryItem.id;
    });
    const library = state.library;
    library.splice(index, 1);
    state.library = library;
  },

  setClassCard(state, classCardData: ClassCardData) {
    if (classCardData.id === null) {
      throw new Error('Cannot set classcard without id');
    }
    const index = state.classCards.findIndex((localItem) => {
      return localItem.id === classCardData.id;
    });

    if (index >= 0) {
      Vue.set(state.classCards, index, classCardData);
    } else {
      state.classCards.push(classCardData);
    }
  },

  setBeasts(state, beasts: Beast[]) {
    state.beasts = beasts;
  },

  addBeast(state, beast: Beast) {
    // check if beast already exists
    const index = state.beasts.findIndex((existingBeast) => existingBeast.id === beast.id);
    if (index > -1) {
      Vue.set(state.beasts, index, beast);
    } else {
      state.beasts.push(beast);
    }
  },

  setBeastValue(state, payload) {
    const index = state.beasts.findIndex((existingBeast) => existingBeast.id === payload.beastId);
    const beast = state.beasts[index];
    const beastValueIndex = beast.values.findIndex((beastValue) => beastValue.name === payload.valueName);
    beast.values[beastValueIndex].value = payload.value;
    Vue.set(state.beasts, index, beast);
  },
};
