












































import Component from 'vue-class-component';
import Vue from 'vue';
import { routes } from '@/router/routes';
import { PropSync } from 'vue-property-decorator';

@Component
export default class Navdrawer extends Vue {

  @PropSync('navdrawerOpen')
  public open!: boolean;

  private routes = routes;
}
