import firebase from 'firebase/app';
import '../firebasesetup';

import { Route } from 'vue-router';
import store from '../store/store';
import Bugsnag, { NotifiableError } from '@bugsnag/js';

/**
 * Declare process variable
 */
declare var process: {
  env: {
    VUE_APP_VERSION: string;
    NODE_ENV: string;
    VUE_APP_FIREBASE_TARGET: string;
    BASE_URL: string;
  };
};

export default (to: Route, from: Route, next) => {
  let unsubscribe: firebase.Unsubscribe | null = null;
  const initializeAuth = new Promise((resolve, reject) => {
    try {
      // this adds a hook for the initial auth-change event
      unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        // In case user is signed in, only resolve after data was loaded
        if (user !== null) {
          // stringify and parse user to break object relation
          store.commit('setUser', JSON.parse(JSON.stringify(user)));
          store.dispatch('loadUserData').then(() => {
            resolve(user as object);
          });
        } else {
          resolve(user);
        }
      });
    } catch (error) {
      if (process.env.NODE_ENV === 'production') {
        Bugsnag.notify(error as NotifiableError);
      }
      reject(error);
    }
  });

  initializeAuth
    .then((user) => {
      // If user is logged in
      if (user != null) {
        // Special case: Instead of homepage forward to loggedIn homepage
        if (to.path === '/') {
          next({ path: '/home', replace: true });
        } else {
          next();
        }

        // If user is not logged in, if homepage everything is fine
      } else if (to.path === '/') {
        next();
        // If any other page is requested, forward to homepage
      } else {
        next('/');
      }

      // Unsubscribe from authstatelistener
      if (unsubscribe !== null) {
        unsubscribe();
      }
    })
    .catch(() => {
      // In case of error, redirect to homepage
      next('/');
    });
};
