import { ActionTree } from 'vuex';
import { GroupState, SaveCharacterModifiersPayload } from './types';
import { RootState } from '../types';
import FirebaseGateway from '@/classes/firebaseGateway';
import { Quest, Journal, QuestTask, QuestTaskDescription } from '@/interfaces/journal/journal';
import { Round } from '@/interfaces/rounds/rounds';
import { Handout } from '@/interfaces/handouts/handouts';
import FirebaseRealtime from '@/classes/firebaseRealtime';
import { CharacterExportData } from '@/classes/character/character';
import { BattleMarker, BattleFieldData } from '@/interfaces/group/battle';
import { UpdateCharacterClassCardsPayload, ClassCardData } from '@/interfaces/character/classCard';
import FirebaseSnapshotListener from '@/classes/firebaseSnapstListener';
import Migrator from '@/classes/migrator';

export const actions: ActionTree<GroupState, RootState> = {
  loadJournal({ rootState, commit }, watch: boolean = true) {
    const currentGroup = rootState.currentGroup;
    if (!currentGroup) {
      throw new Error('No current group set');
    }

    const gateway = new FirebaseGateway();
    const journalPromise = gateway.getJournal(currentGroup);
    journalPromise.then((journal: Journal) => {
      commit('setJournal', journal);
    });
    // Also start watching the journal for changes
    if (watch) {
      FirebaseRealtime.watchJournal(commit, currentGroup);
    }
    return journalPromise;
  },

  loadBattle({ rootState, commit }) {
    const currentGroup = rootState.currentGroup;
    if (!currentGroup) {
      throw new Error('No current group set');
    }
    // Start watching the battle for updates
    FirebaseRealtime.watchBattle(commit, currentGroup);
    return Promise.resolve(true);
  },

  updateMarker({ rootState }, marker: BattleMarker) {
    const currentGroup = rootState.currentGroup;
    if (!currentGroup) {
      throw new Error('No current group set');
    }
    // Also start watching the journal for changes
    return FirebaseRealtime.updateMarker(currentGroup, marker);
  },

  removeMarker({ rootState }, markerId: string) {
    const currentGroup = rootState.currentGroup;
    if (!currentGroup) {
      throw new Error('No current group set');
    }
    // Also start watching the journal for changes
    return FirebaseRealtime.removeMarker(currentGroup, markerId);
  },

  newBattle({ rootState }, imageUrl: string) {
    const currentGroup = rootState.currentGroup;
    if (!currentGroup) {
      throw new Error('No current group set');
    }
    return FirebaseRealtime.newBattle(currentGroup, imageUrl);
  },

  /**
   * Adds a new quest to the journal
   */
  addNewQuest({ commit, rootState }, quest: Quest) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('No current group set');
    }
    const gateway = new FirebaseGateway();
    gateway.addQuest(rootState.currentGroup, quest).then(() => {
      commit('addQuest', quest);
    });
  },

  deleteQuest({ dispatch, rootState }, questId) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('No current group set');
    }
    const gateway = new FirebaseGateway();
    return gateway.deleteQuest(rootState.currentGroup, questId).then(() => {
      return dispatch('loadJournal');
    });
  },

  updateQuest({ dispatch, rootState }, quest) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('No current group set');
    }
    const gateway = new FirebaseGateway();
    return gateway.updateQuest(rootState.currentGroup, quest).then(() => {
      return dispatch('loadJournal');
    });
  },

  changeQuestOrder({ dispatch, rootState }, payload) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('No current group set');
    }
    const gateway = new FirebaseGateway();
    return gateway.changeQuestOrder(rootState.currentGroup, payload.quest, payload.newIndex).then(() => {
      return dispatch('loadJournal');
    });
  },

  /**
   * Adds a new quest to the journal
   */
  addNewTask({ commit, rootState }, payload) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('No current group set');
    }
    const user = rootState.user;
    if (user === null) {
      throw new Error('user is null');
    }
    const questTask: QuestTask = {
      title: payload.title,
      id: Math.round(Math.random() * 100000000).toString(),
      descriptions: [],
      author: user.uid,
    };
    const questId = payload.questId;
    const gateway = new FirebaseGateway();
    const returnPromise = gateway.addQuestTask(rootState.currentGroup, questId, questTask);

    returnPromise.then(() => {
      commit('addQuestTask', { questTask, questId });
    });
    return returnPromise;
  },

  /**
   * Adds a new quest to the journal
   */
  deleteQuestTask({ dispatch, rootState }, payload) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('No current group set');
    }
    const user = rootState.user;
    if (user === null) {
      throw new Error('user is null');
    }
    const questId = payload.questId;
    const questTaskId = payload.questTaskId;
    const gateway = new FirebaseGateway();
    const returnPromise = gateway.deleteQuestTask(rootState.currentGroup, questId, questTaskId);

    return returnPromise.then(() => {
      return dispatch('loadJournal');
    });
  },

  /**
   * Adds a new quest to the journal
   */
  addNewTaskDescription({ commit, rootState }, payload) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('No current group set');
    }
    const user = rootState.user;
    if (user === null) {
      throw new Error('user is null');
    }
    const questTaskDescription: QuestTaskDescription = {
      text: payload.text,
      id: Math.round(Math.random() * 100000000).toString(),
      author: user.uid,
      createdAt: Math.round(new Date().getTime() / 1000),
    };
    const questId = payload.questId;
    const questTaskId = payload.questTaskId;
    const gateway = new FirebaseGateway();
    const returnPromise = gateway.addQuestTaskDescription(
      rootState.currentGroup,
      questId,
      questTaskId,
      questTaskDescription,
    );

    returnPromise.then(() => {
      commit('addQuestTaskDescription', { questTaskDescription, questId, questTaskId });
    });
    return returnPromise;
  },

  saveRound({ commit, rootState }, round: Round) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('Currentgroup not set');
    }
    return FirebaseGateway.saveRound(rootState.currentGroup, round)
      .then((roundId) => {
        round.id = roundId;
        commit('setRound', round);
        return round;
      })
      .catch((error) => {
        console.log('error in actions caught', error);
        throw error;
      });
  },
  loadRound({ commit, rootState }, roundId) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('Currentgroup not set');
    }
    return FirebaseGateway.loadRound(rootState.currentGroup, roundId).then((round) => {
      commit('setRound', round);
      return round;
    });
  },
  loadRoundShorts({ commit, rootState }) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('Currentgroup not set');
    }
    // Watch the rounds for current edits
    FirebaseRealtime.watchRounds(commit, rootState.currentGroup);

    return FirebaseGateway.loadGroup(rootState.currentGroup).then((groupData: GroupState) => {
      if (Array.isArray(groupData.roundsShort) && groupData.roundsShort.length > 0) {
        commit('setRoundShorts', groupData.roundsShort);
      }
      commit('setGroupMembers', groupData.members);
      commit('setGroupCharacterShorts', groupData.characterShorts);
      return groupData.roundsShort;
    });
  },
  loadGroup({ commit, rootState }) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('Currentgroup not set');
    }
    return FirebaseGateway.loadGroup(rootState.currentGroup).then((groupData: GroupState) => {
      commit('setGroupMembers', groupData.members);
      commit('setGroupMasters', groupData.masters);
      if (groupData.characterShorts) {
        commit('setGroupCharacterShorts', groupData.characterShorts);
      }
      return groupData.members;
    });
  },
  loadHandouts({ commit, rootState }) {
    if (!rootState.currentGroup || !rootState.user) {
      throw new Error('Either group or user is null');
    }
    return FirebaseGateway.loadHandouts(rootState.currentGroup, rootState.user.uid).then((handouts) => {
      commit('setHandouts', handouts);
      return handouts;
    });
  },
  uploadHandout({ commit, rootState }, handout: Handout) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('Currentgroup not set');
    }

    return FirebaseGateway.uploadHandout(rootState.currentGroup, handout).then((handoutId) => {
      handout.id = handoutId;
      commit('setHandout', handout);
      return true;
    });
  },
  deleteHandout({ commit, rootState }, handout: Handout) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('Currentgroup not set');
    }

    return FirebaseGateway.deleteHandout(rootState.currentGroup, handout).then((handoutId) => {
      commit('removeHandout', handout);
      return true;
    });
  },
  loadBattleImages({ commit, state, rootState }) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('Currentgroup not set');
    }

    commit('setBattleImages', []);

    return FirebaseGateway.loadBattleImages(rootState.currentGroup).then((battleImages) => {
      battleImages.forEach((battleImageDoc) => {
        commit('addBattleImage', battleImageDoc.data());
      });
      return true;
    });
  },
  uploadBattleImage({ commit, rootState }, battleImage: BattleFieldData) {
    if (typeof rootState.currentGroup === 'undefined' || rootState.currentGroup === null) {
      throw new Error('Currentgroup not set');
    }

    return FirebaseGateway.uploadBattleImageData(rootState.currentGroup, battleImage).then((battleImageId) => {
      commit('addBattleImage', battleImage);
      return true;
    });
  },

  loadCharacter({ rootState, state, rootGetters }, characterId: string) {
    if (!rootState.currentGroup) {
      throw new Error('No group selected');
    }
    // In case this character was already loaded, return true immediately
    const index = state.characters.findIndex((character) => character.characterId === characterId);
    if (index !== -1) {
      return Promise.resolve(state.characters[index]);
    }
    return FirebaseGateway.loadCharacter(rootState.currentGroup, characterId).then((characterData) => {
      return Migrator.migrateCharacter(characterData, rootGetters.currentWorld, rootGetters.currentGroup).then(
        (migratedCharacter) => {
          this.commit('addOrUpdateCharacter', migratedCharacter);
          return characterData;
        },
      );
    });
  },

  async watchCharacters({ rootState, commit }) {
    const groupId = rootState.currentGroup;
    if (groupId === null) {
      throw new Error('No group selected');
    }
    FirebaseSnapshotListener.listenOnCharacters(commit, groupId);

    return true;
  },

  saveCharacter({ rootState }, character: CharacterExportData) {
    if (!rootState.currentGroup) {
      throw new Error('No group selected');
    }
    if (!rootState.user) {
      throw new Error('User not set');
    }
    character.userId = rootState.user.uid;
    return FirebaseGateway.saveCharacter(rootState.currentGroup, character);
  },
  setActiveClassCard({ rootState, state }, payload) {
    if (!rootState.currentGroup) {
      throw new Error('No group selected');
    }
    // Get the stored activeClassCards
    const char = state.characters.find((character) => character.characterId === payload.characterId);
    if (typeof char === 'undefined') {
      throw new Error('Character not found ' + payload.characterId);
    }
    let classCards: Array<ClassCardData | null> = [null, null, null, null, null];
    if (typeof char.activeClasscards !== 'undefined') {
      classCards = char.activeClasscards;
    }
    classCards[payload.index] = payload.classCard;
    return FirebaseGateway.updateActiveClassCards(rootState.currentGroup, payload.characterId, classCards);
  },

  updateCharacterClassCards({ rootState, state, commit }, payload: UpdateCharacterClassCardsPayload) {
    if (!rootState.currentGroup) {
      throw new Error('No group selected');
    }
    return FirebaseGateway.updateCharacterClassCards(
      rootState.currentGroup,
      payload.characterId,
      payload.classCards,
    ).then(() => {
      const foundCharacterIndex = state.characters.findIndex(
        (character) => character.characterId === payload.characterId,
      );
      if (foundCharacterIndex === -1) {
        throw new Error('Wanted to update classcards of character ' + payload.characterId + ' but did not find it');
      }
      const foundCharacter = state.characters[foundCharacterIndex];
      foundCharacter.classcards = payload.classCards;
      commit('addOrUpdateCharacter', foundCharacter);
    });
  },

  changeCharacterCurrentValues({ rootState }, payload) {
    if (!rootState.currentGroup) {
      throw new Error('No group selected');
    }
    return FirebaseGateway.updateCharacterCurrentValue(
      rootState.currentGroup,
      payload.characterId,
      payload.currentValues,
    );
  },

  updateCharacterDoubts({ rootState }, payload) {
    if (!rootState.currentGroup) {
      throw new Error('No group selected');
    }
    return FirebaseGateway.updateCharacterDoubts(rootState.currentGroup, payload.characterId, payload.doubts);
  },

  updateCharacterText({ rootState }, payload) {
    if (!rootState.currentGroup) {
      throw new Error('No group selected');
    }
    return FirebaseGateway.updateCharacterText(rootState.currentGroup, payload.characterId, payload.miscText);
  },

  saveCharacterModifiers({ rootState }, payload: SaveCharacterModifiersPayload) {
    if (!rootState.currentGroup) {
      throw new Error('No group selected');
    }
    return FirebaseGateway.saveCharacterModifiers(rootState.currentGroup, payload.characterId, payload.modifiers);
  },

  /**
   * SESSION SPECIFIC METHODS
   */
  loadSession({ commit, rootState }) {
    const currentGroup = rootState.currentGroup;
    if (!currentGroup) {
      throw new Error('No current group set');
    }

    // start watching the session for changes
    return FirebaseRealtime.watchSession(commit, currentGroup);
  },

  startSession({ rootState }) {
    if (rootState.user === null) {
      throw new Error('User is null');
    }
    if (rootState.currentGroup === null || typeof rootState.currentGroup === 'undefined') {
      throw new Error('currentGroup is null');
    }
    const currentUser = rootState.user.uid;
    const currentGroup = rootState.currentGroup;
    FirebaseRealtime.startSession(currentGroup, currentUser);
  },

  stopSession({ rootState }) {
    if (rootState.currentGroup === null || typeof rootState.currentGroup === 'undefined') {
      throw new Error('currentGroup is null');
    }
    const currentGroup = rootState.currentGroup;
    FirebaseRealtime.stopSession(currentGroup);
  },

  setSessionAudio({ rootState }, audioState: boolean) {
    if (rootState.currentGroup === null || typeof rootState.currentGroup === 'undefined') {
      throw new Error('currentGroup is null');
    }
    const currentGroup = rootState.currentGroup;
    FirebaseRealtime.setSessionAudio(currentGroup, audioState);
  },
};
