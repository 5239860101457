




























































































import Vue from 'vue';
import loginform from './loginform.vue';
import Friend from '@/interfaces/Friend';
import firebase from 'firebase/app';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { world } from '../store/world';
import { routes, ChildRoute } from '@/router/routes';

interface User {
  uid: string;
}

interface UserData {
  name: string;
}

@Component({
  components: { loginform },
})
export default class Navbar extends Vue {
  @Prop()
  private friends!: Friend[];

  private drawerOpen = false;
  private currentActiveSection: null | string = null;

  get navRoutes() {
    if (routes === null || !Array.isArray(routes) || routes.length === 0) {
      throw new Error('Empty routes');
    }
    // Error checking
    routes.forEach((mainRoute) => {
      if (typeof mainRoute.title === 'undefined') {
        throw new Error('Route has no title');
      }
      if (typeof mainRoute.children === 'undefined') {
        throw new Error('Route has no children: ' + mainRoute.title);
      }
      if (mainRoute.children.length < 1) {
        throw new Error('Route has empty children: ' + mainRoute.title);
      }
    });
    return routes;
  }

  constructor() {
    super();
  }

  public cloudSaveEntered(el) {
    el.classList.add('cloud-save-animation');
  }

  public cloudSaveLeave(el) {
    el.classList.remove('cloud-save-animation');
  }

  get user(): User {
    return this.$store.getters.getUser;
  }
  get name(): string {
    let name: string = '';
    if (this.userData != null) {
      name = this.userData.name;
    }
    return name;
  }
  get currentGroup() {
    return this.$store.getters.currentGroup;
  }
  get hasCurrentWorld() {
    return this.$store.getters.currentWorld !== null;
  }
  get friendsOnline() {
    let online = 0;
    this.friends.forEach((friend) => {
      if (friend.status === 'online') {
        online++;
      }
    });

    return online;
  }

  get userData() {
    return this.$store.getters.userData;
  }

  get isSaving(): boolean {
    return this.$store.getters.isSaving;
  }

  get version(): string {
    return this.$store.getters.version;
  }

  private isRouteDisabled(route: ChildRoute) {
    if (typeof route.disabledIf === 'undefined') {
      return false;
    }

    if (route.disabledIf === 'no-group' && !this.currentGroup) {
      return true;
    }

    if (route.disabledIf === 'no-world' && !this.hasCurrentWorld) {
      return true;
    }

    return false;
  }

  @Watch('$route')
  private onRouteChanged(to, from) {
    const worldRoutes = ['/welt', '/karte', '/bibliothek'];
    const groupRouters = ['/journal', '/group', '/fights', '/spielabende', '/handouts'];
    if (worldRoutes.includes(this.$router.currentRoute.path)) {
      this.currentActiveSection = 'Welt';
    } else if (groupRouters.find((route) => this.$router.currentRoute.path.includes(route))) {
      this.currentActiveSection = 'Spielgruppe';
    } else {
      this.currentActiveSection = null;
    }
  }

  private signOutUser() {
    const me = this;
    firebase
      .database()
      .ref('/status/' + me.user.uid)
      .set({
        state: 'offline',
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      })
      .then(() => {
        firebase
          .auth()
          .signOut()
          .then(
            () => {
              me.$store.dispatch('signoutUser').then(() => {
                window.location.reload();
              });
            },
            (error) => {
              alert(error.message);
            },
          );
      });
  }

  private navigateToHome() {
    // Do nothing if we are already on home
    if (['/', '/home'].includes(this.$route.path)) {
      return;
    }
    if (this.user === null) {
      this.$router.push('/');
    } else {
      this.$router.push('/home');
    }
  }

  private navigateToDashboard() {
    this.$store.commit('setCurrentFight', null);
    this.$router.push('/dashboard');
  }
}
