import Vue from 'vue';
import { UserLog } from '@/interfaces/UserLog';
import { RootState } from './types';
import Bugsnag from '@bugsnag/js';
import UserData from '@/interfaces/UserData';
import { PnPTheme } from '@/interfaces/settings/theme';

export default {
  setUser(state, user) {
    state.user = user;

    // Set user information for monitoring
    if (user != null) {
      if (process.env.NODE_ENV === 'production') {
        Bugsnag.setUser(user.uid, user.email, user.displayName);
      }
    }
  },

  setUserData(state: RootState, userData: UserData) {
    // set fields that were added later to prevent errors
    if (userData !== null) {
      if (typeof userData.currentGroup !== 'undefined') {
        state.currentGroup = userData.currentGroup;
      }
      state.groups = [];
      if (typeof userData.groups !== 'undefined') {
        for (const groupId in Object.keys(userData.groups)) {
          if (typeof userData.groups[groupId] !== 'undefined') {
            state.groups.push(userData.groups[groupId]);
          }
        }
      }

      if (typeof userData.settings !== 'undefined') {
        if (userData.settings.hasOwnProperty('backgroundImage')) {
          // Migration state
          if (userData.settings.background === 'Battle.jpg') {
            userData.settings.background = 'Background0.jpg';
          }
        }
        state.theme = userData.settings;
      }
    }

    state.userData = userData;
  },

  setUserName(state, userName) {
    state.userData.name = userName;
  },

  /**
   * Adds the passed id of a fight to list of fights
   * for this user.
   *
   * @param state
   * @param fightId
   */
  addFightToUser(state, fightId: string) {
    state.userData.fights.push(fightId);
  },

  setCurrentGroup(state, groupId) {
    state.currentGroup = groupId;
  },

  setCurrentGroupData(state, groupData) {
    state.currentGroupData = groupData;
  },

  addGroup(state, group) {
    const index = state.groups.findIndex((existingGroup) => {
      return existingGroup.id === group.id;
    });
    if (index === -1) {
      state.groups.push(group);
    } else {
      Vue.set(state.groups, index, group);
    }
  },

  setSaving(state, isSaving) {
    state.isSaving = isSaving;

    if (isSaving) {
      state.lastSave = Math.round(new Date().getTime() / 1000);
    }
  },

  addMessage(state, message) {
    const messageObject = {
      id: Math.round(Math.random() * 100000),
      message,
    };
    state.messages.push(messageObject);
  },

  removeLastMessage(state) {
    state.messages.shift();
  },

  setTheme(state, payload: PnPTheme) {
    state.theme = payload;
  },

  addUserLogs(state, userLogs: UserLog[]) {
    state.userLog = userLogs;
  },
};
