import firebase from 'firebase/app';
import 'firebase/firestore';

const productionConfig = {
  apiKey: 'AIzaSyDzFb8JmkLlaJLeSGsqMKRe1TavA_2cWBI',
  authDomain: 'pnp-utilities.firebaseapp.com',
  databaseURL: 'https://pnp-utilities.firebaseio.com',
  projectId: 'pnp-utilities',
  storageBucket: 'pnp-utilities.appspot.com',
  messagingSenderId: '385636344047',
  appId: '1:385636344047:web:f1eb6109dc25bb23613b1a',
};
const devConfig = {
  apiKey: 'AIzaSyA0zm498YETFWZF0Occx9z82DVtmh-lk8g',
  authDomain: 'pnp-utilities-dev.firebaseapp.com',
  databaseURL: 'https://pnp-utilities-dev.firebaseio.com',
  projectId: 'pnp-utilities-dev',
  storageBucket: 'pnp-utilities-dev.appspot.com',
  messagingSenderId: '112357461034',
  appId: '1:112357461034:web:73a686ca621a1c8fa24753',
};
if (process.env.VUE_APP_FIREBASE_TARGET === 'beta') {
  firebase.initializeApp(devConfig);
} else {
  firebase.initializeApp(productionConfig);
}
// firebase.firestore.setLogLevel('debug');
// firebase.firestore().settings({});
