



























import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Fighter from '@/interfaces/Fighter';

@Component
export default class Sequence extends Vue {
  @Prop()
  private fighters!: Fighter[];

  @Prop()
  private hoveredFighter!: string;

  get fighterSequence() {
    let fightersArray: Fighter[] = JSON.parse(JSON.stringify(this.fighters));
    fightersArray = fightersArray.filter((fighter) => fighter.alive && !fighter.inactive);

    fightersArray.sort((a, b) => {
      // In case sequence value is identical, use initiative
      const initialDifference = b.sequenceValue - a.sequenceValue;
      if (initialDifference === 0) {
        return b.type === 'enemy' ? 1 : -1;
      }
      return initialDifference;
    });
    return fightersArray;
  }

  get allFightersDone() {
    let allFightersDone = true;
    this.fighters.forEach((fighter) => {
      allFightersDone = allFightersDone && !!fighter.done;
    });
    return allFightersDone;
  }

  private getClasses(fighter: Fighter) {
    const classes = ['type-' + fighter.type, fighter.id === this.hoveredFighter ? 'hover' : ''];
    if (typeof fighter.short !== 'undefined') {
      classes.push(fighter.short.toLowerCase());
    }
    if (fighter.done) {
      classes.push('done');
    }
    return classes;
  }

  private getAbbreviation(fighter: Fighter) {
    let i = 0;
    let result = '';
    let length = 2;

    // split into words
    const parts = fighter.name.split(' ');

    if (fighter.type === 'player') {
      for (i = 0; i < length; i++) {
        if (parts.length > i) {
          result += parts[i].substr(0, 1);
        } else {
          result += parts[parts.length - 1].substr(i + 1 - parts.length, 1);
        }
      }

      return result.toUpperCase();
    } else {
      length = 4;

      for (i = 0; i < length; i++) {
        if (parts.length > i) {
          result += parts[i].substr(0, 1);
        } else {
          result += parts[parts.length - 1].substr(i + 1 - parts.length, 1);
        }
      }

      return fighter.name;
    }
  }

  private hover(id, isHover) {
    this.$emit('fighterhover', id, isHover);
  }

  private click(id) {
    this.$emit('fighterclick', id);
  }
}
