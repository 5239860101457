import { GetterTree } from 'vuex';
import { WorldState } from './types';
import { RootState } from '../types';
import { WorldMarker } from '@/interfaces/Marker';
import { World } from '@/interfaces/world/world';
import { AudioLibraryItem, LibraryItem } from '@/interfaces/world/library';
import { ClassCardData } from '@/interfaces/character/classCard';

export const getters: GetterTree<WorldState, RootState> = {
  /**
   * Returns the current fight for this user
   * @param state
   */
  markers(state): WorldMarker[] {
    return state.markers;
  },

  persons(state) {
    return state.persons;
  },

  personTags(state): object {
    const tagsMap: object = {};
    if (Array.isArray(state.persons)) {
      state.persons.forEach((person) => {
        let tags = ['keine Tags'];
        if (typeof person.tags !== 'undefined' && Array.isArray(person.tags)) {
          tags = person.tags;
        }

        tags.forEach((tag) => {
          if (!tagsMap.hasOwnProperty(tag)) {
            tagsMap[tag] = 1;
          } else {
            tagsMap[tag]++;
          }
        });
      });
    }
    return tagsMap;
  },

  currentWorld(state): string | null {
    return state.currentWorld;
  },

  availableWorlds(state): World[] {
    return state.availableWorlds;
  },

  isCurrentWorldEditor(state, localGetters) {
    const currentWorld = state.currentWorld;
    if (currentWorld === null) {
      return false;
    }
    return localGetters.isWorldEditor(currentWorld);
  },

  isWorldEditor(state, localGetters, rootState, rootGetters) {
    return (worldSlug) => {
      const foundWorld = state.availableWorlds.find((world) => world.slug === worldSlug);
      if (typeof foundWorld === 'undefined') {
        return false;
      } else {
        if (foundWorld.editors === null || typeof foundWorld.editors === 'undefined') {
          return false;
        }
        return typeof foundWorld.editors[rootGetters.userId] !== 'undefined';
      }
    };
  },

  library(state): LibraryItem[] {
    return state.library;
  },

  audioLibrary(state): AudioLibraryItem[] {
    return state.audiothek;
  },

  classCards(state): ClassCardData[] {
    return state.classCards;
  },

  classCardsByClass(state) {
    return (classValue) => {
      return state.classCards.filter((card) => card.classValue === classValue);
    };
  },

  getClassCardsByIds(state) {
    return (classCardIds: string[]) => {
      return classCardIds.map((classCardId) => {
        const foundCard = state.classCards.find((card) => card.id === classCardId);
        if (typeof foundCard === 'undefined') {
          throw new Error('Could not find classcard with id ' + classCardId);
        }
        return foundCard;
      });
    };
  },

  beasts(state) {
    return state.beasts;
  },
};
