import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { FightState } from './types';
import { RootState } from '../types';

export const state: FightState = {
  currentFight: {
    id: null,
    fighters: [],
    players: [],
    currentRound: 0,
    rounds: [],
    created: { seconds: 0, nanoseconds: 0 },
  },
  fights: [],
  presets: [],
};

const namespaced: boolean = false;

export const fight: Module<FightState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
