






































































































































































































































import sequence from './sequence.vue';
import fighter from './fighter.vue';
import availableIcons from '../../constants/icons';
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';
import FighterInterface from '@/interfaces/Fighter';
import FirebaseGateway from '@/classes/firebaseGateway';
import Fighter from '@/interfaces/Fighter';
import { Beast } from '@/interfaces/world/beast';

@Component({
  components: { sequence, fighter },
})
export default class Fight extends Vue {
  @Getter
  private currentFight!: Fight;

  @Getter
  private fighterPresets!: FighterInterface[];

  @Getter
  private beasts!: Beast[];

  get players() {
    if (this.currentFight == null) {
      return [];
    } else {
      return this.currentFight.players;
    }
  }

  get currentRound() {
    if (this.currentFight == null) {
      return 1;
    } else {
      return this.currentFight.currentRound;
    }
  }

  get fighters(): Fighter[] {
    if (this.currentFight == null) {
      return [];
    } else {
      return this.currentFight.fighters;
    }
  }

  get combinedPresets() {
    const beastPresets: Fighter[] = [];
    this.beasts.forEach((beast) => {
      if (beast.id === null) {
        return;
      }

      const beastPreset: Fighter = {
        id: beast.id,
        name: beast.name,
        lep: 0,
        sequenceValue: 0,
        type: 'enemy',
        isBeast: true,
      };

      // Loop over values that could be added to preset
      const valuesToConvert = ['ini', 'aip', 'lep', 'ar'];
      valuesToConvert.forEach((valueToConvert) => {
        const foundValue = beast.values.find((value) => value.name.toLowerCase() === valueToConvert)?.value;

        // Value needs to be there and plain number
        if (typeof foundValue === 'string') {
          // Check if it is a formula
          const reg = new RegExp(/^([0-9]*(gw)*[+-/*()]*)+$/, 'ig');
          let setValue = 0;
          if (reg.test(foundValue)) {
            // replace the token
            const replacedString = foundValue.replace(/gw/gi, '5');
            // tslint:disable-next-line:no-eval
            const calculatedValue = eval(replacedString);
            setValue = calculatedValue;
          } else if (Number.isInteger(parseInt(foundValue, 10))) {
            setValue = parseInt(foundValue, 10);
          } else {
            return;
          }

          switch (valueToConvert) {
            case 'ar':
              beastPreset.sequenceValue = setValue;
              break;
            default:
              beastPreset[valueToConvert] = setValue;
          }
        }
      });

      if (beast.imageUrl !== null) {
        beastPreset.image = beast.imageUrl;
      }
      beastPresets.push(beastPreset);
    });

    return [...this.fighterPresets, ...beastPresets].sort((a, b) => (a.name < b.name ? -1 : 1));
  }

  get rounds() {
    if (this.currentFight == null) {
      return [];
    } else {
      const rounds = JSON.parse(JSON.stringify(this.currentFight.rounds));
      rounds.reverse();
      return rounds;
    }
  }

  get selectedPresetFighter() {
    if (this.selectedFighterPreset === null) {
      return null;
    }
    const foundPreset = this.combinedPresets.find((preset) => preset.id === this.selectedFighterPreset);

    if (typeof foundPreset === 'undefined') {
      return null;
    }

    return foundPreset;
  }

  get selectedPresetFighterMargins() {
    if (this.selectedFighterPreset === null) {
      return null;
    }
    const foundBeast = this.beasts.find((beast) => beast.id === this.selectedFighterPreset);

    if (typeof foundBeast === 'undefined') {
      return null;
    }

    return foundBeast.classMargins?.map((margin) => (margin === null ? '-' : margin)).join(' / ');
  }

  /**
   * The "add preset" is invalid, if no preset was selected, or if this preset is a beast and has
   * no danger level selected
   */
  get presetAddVInvalid() {
    if (this.selectedFighterPreset === null || this.selectedPresetFighter === null) {
      return true;
    }
    if (!this.selectedPresetFighter.isBeast) {
      return false;
    }
    return this.selectedPresetDangerLevel === null || this.selectedPresetDangerLevel === '';
  }

  @Prop()
  private fightId!: string | null;

  private error: string | null = null;
  private newFighter: FighterInterface = {
    id: null,
    name: 'Kämpfer',
    lep: 0,
    type: 'enemy',
    alive: true,
    bleeders: [],
    wounds: 0,
    sequenceValue: 0,
    icon: 'swords-crossed',
  };
  private hoveredFighter: string | null = null;
  private availableIcons: string[] = availableIcons;
  private newFighterModal: boolean = false;
  private expansionModel: number[] = [0];
  private showCurrentRound: boolean = true;
  private loading: boolean = true;
  private fighterPresetDialog = false;
  private fighterPresetsLoading = true;
  private selectedFighterPreset: string | null = null;
  private selectedPresetDangerLevel: string | null = null;
  private deletingPresetId: string | null = null;

  /**
   * We need this in case the fight was entered directly through the URL
   */
  public mounted() {
    this.$store.dispatch('loadFighterPresets').then(() => {
      this.fighterPresetsLoading = false;
    });
  }

  public addPlayer(shortName, longName, id): void {
    if (this.fighterWithIdAlreadyPresent(id)) {
      return;
    }

    const player = {
      name: longName,
      short: shortName,
      id,
      alive: true,
      type: 'player',
      initiative: 0,
      wounds: 0,
      inactive: false,
      color: 'green',
      bleeders: [],
      isHover: false,
    };

    this.$store.dispatch('addPlayer', player);
  }

  public addFighterFromPreset() {
    if (this.selectedPresetFighter === null) {
      throw new Error('No preset selected');
    }

    // Fetch the selected preset from the list of presets
    const selectedPreset = this.selectedPresetFighter;

    if (selectedPreset.isBeast) {
      // Find the corresponding beast
      const beast = this.beasts.find((localBeast) => localBeast.id === selectedPreset.id);

      if (typeof beast === 'undefined') {
        throw new Error('Could not find beast with id ' + selectedPreset.id);
      }

      // Loop over values that could be added to preset
      const valuesToConvert = ['ini', 'aip', 'lep', 'ar'];
      valuesToConvert.forEach((valueToConvert) => {
        const foundValue = beast.values.find((value) => value.name.toLowerCase() === valueToConvert)?.value;

        if (this.selectedPresetDangerLevel === null) {
          throw new Error('No danger selected');
        }

        // Value needs to be there and plain number
        if (typeof foundValue === 'string') {
          // Check if it is a formula
          const reg = new RegExp(/^([0-9]*(gw)*[+-/*()]*)+$/, 'ig');
          let setValue = 0;
          if (reg.test(foundValue)) {
            // replace the token
            const replacedString = foundValue.replace(/gw/gi, this.selectedPresetDangerLevel);
            // tslint:disable-next-line:no-eval
            const calculatedValue = eval(replacedString);
            setValue = calculatedValue;
          } else if (Number.isInteger(parseInt(foundValue, 10))) {
            setValue = parseInt(foundValue, 10);
          } else {
            return;
          }

          switch (valueToConvert) {
            case 'ar':
              selectedPreset.sequenceValue = setValue;
              break;
            default:
              selectedPreset[valueToConvert] = setValue;
          }
        }
      });
    }

    // Simple merge the desired values into the new fighter model
    this.newFighter = {
      ...this.newFighter,
      ...selectedPreset,
    };
    // We need to randomize the id, otherwise multiple fighters might end up with same id
    this.newFighter.id = Math.floor(Math.random() * 1000000).toString();
    this.addFighter();

    // Reset the input field afterwards
    this.selectedFighterPreset = null;
  }

  public addFighter() {
    const id = Math.floor(Math.random() * 1000000);

    const name = this.newFighter.name !== '' ? this.newFighter.name : 'Kämpfer ' + this.fighters.length;

    this.$store.dispatch('addFighterToCurrentFight', {
      id,
      name,
      short: 'standardFighter',
      lep: this.newFighter.lep,
      lepMax: this.newFighter.lep,
      sequenceValue: this.newFighter.sequenceValue,
      enemyPlayer: null,
      alive: true,
      wounds: 0,
      bleeders: [],
      inactive: false,
      done: false,
      color: this.newFighter.color ? this.newFighter.color : 'red',
      type: 'enemy',
      icon: this.newFighter.image ? null : this.newFighter.icon,
      image: this.newFighter.image,
      isHover: false,
    });

    // Reset values to empty
    this.newFighter = {
      id: null,
      icon: 'swords-crossed',
      name: '',
      type: 'enemy',
      alive: true,
      bleeders: [],
      lep: 0,
      wounds: 0,
      sequenceValue: 0,
    };
    this.newFighterModal = false;
  }

  public sortFighters() {
    this.$store.dispatch('sortFighters');
  }

  public resetRound() {
    const fighterIds = this.fighters
      .filter((singleFighter) => singleFighter.done)
      .map((singleFighter) => singleFighter.id);
    this.$store.dispatch('setAllFightersNotDone', fighterIds);
  }

  public nextRound() {
    // This makes a clone
    let fightersCopy = JSON.parse(JSON.stringify(this.fighters));
    fightersCopy.forEach((localFighter) => {
      localFighter.inactive = true;
    });

    // Store the current state as a new round
    this.$store.commit('saveRound', fightersCopy);

    // Remove dead fighters
    fightersCopy = this.fighters.filter((localFighter) => localFighter.alive);

    // Set all fighters as not done
    this.fighters.forEach((localFighter) => {
      localFighter.done = false;
    });

    // Go through bleeders, pssoibly mark fighters as dead
    // Don't remove them right away because we want to show them
    // as deceased in the next turn
    fightersCopy.forEach((copiedFighter) => {
      copiedFighter.bleeders.forEach((bleeder) => {
        bleeder.turns--;
        copiedFighter.lep -= bleeder.damage;

        // Kill if dead
        if (copiedFighter.lep <= 0) {
          copiedFighter.alive = false;
        }
      });
      copiedFighter.bleeders = copiedFighter.bleeders.filter((bleeder) => {
        return bleeder.turns > 0;
      });
    });

    // increase the round
    this.$store.dispatch('nextRound', fightersCopy);
  }

  public newFight() {
    this.$store.dispatch('createFight').then((fightId) => {
      // Forward to the new fight
      this.$router.push('/fight/' + fightId);
    });
  }

  public fighterWithIdAlreadyPresent(id): boolean {
    // Only add it, if it is not currently in the fight
    let fighterAlreadyPresent = false;
    this.fighters.forEach((localFighter) => {
      if (localFighter.id === id) {
        fighterAlreadyPresent = true;
      }
    });

    return fighterAlreadyPresent;
  }

  public created() {
    this.initializeLogic();
  }

  private initializeLogic() {
    if (this.fightId != null) {
      const firebaseGateway = new FirebaseGateway();

      firebaseGateway.getFight(this.fightId).then((docRef) => {
        if (docRef === null) {
          // fight not found, maybe deleted?
          this.error = 'Fight not found';
        } else {
          // Transform the fight, collect rounds into array
          const currentFight = docRef.data();

          if (typeof currentFight === 'undefined') {
            throw new Error('fight ' + this.fightId + 'not found');
          }

          currentFight.id = this.fightId;
          currentFight.rounds = [];
          for (const key in currentFight) {
            if (/round_/.test(key)) {
              const index = key.split('_');
              if (index.length !== 2) {
                throw new Error('Round index looks wrong: ' + key);
              }
              currentFight.rounds.splice(index[1], 0, currentFight[key]);
              delete currentFight[key];
            }
          }

          // Check structure of fighters
          currentFight.fighters.forEach((currentFighter) => {
            if (!currentFighter.hasOwnProperty('enemyPlayer')) {
              currentFighter.enemyPlayer = null;
            }
          });

          this.$store.dispatch('setCurrentFight', currentFight).then(() => {
            this.loading = false;
          });
        }
      });
    }
  }

  @Watch('rounds')
  private onRoundChanged(oldValue, newValue) {
    if (!this.loading) {
      // The newest round will only be the latest
      this.expansionModel = [this.currentRound - 1];
    }
  }

  @Watch('$route')
  private onRouteChanged(to, from) {
    this.initializeLogic();
  }

  private hoverFighter(id, isHover) {
    if (!isHover) {
      this.hoveredFighter = null;
    } else {
      this.hoveredFighter = id;
    }
  }

  /**
   * Marks the clicked fighter as "done"
   */
  private clickFighter(fighterId: string) {
    this.$store.dispatch('toggleFighterDone', fighterId);
  }

  private deletePreset(preset: FighterInterface) {
    this.$store.dispatch('deletePreset', preset.id).then(() => {
      this.$store.commit('addMessage', 'Vorlage gelöscht: ' + preset.name);
    });
  }
}
