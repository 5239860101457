import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { WorldState } from './types';
import { RootState } from '../types';

export const state: WorldState = {
  persons: [],
  markers: [],
  currentWorld: null,
  availableWorlds: [],
  library: [],
  audiothek: [],
  classCards: [],
  beasts: [],
};

const namespaced: boolean = false;

export const world: Module<WorldState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
