<template>
  <v-container>
    <section>
      <v-layout column align-center justify-center class="white--text">
        <h1 class="white--text mb-2 display-1 text-xs-center">Pen &amp; Paper Utilities (Alpha)</h1>
        <div class="subheading mb-3 text-xs-center">{{ $t('home.claim') }}</div>
        <loginform :flat-button="false" />
      </v-layout>
    </section>

    <section>
      <v-layout column wrap class="my-5" align-center>
        <v-flex xs12 sm6 class="my-3">
          <div class="text-xs-center">
            <h2>Under development</h2>
            <span class="subheading">This tool is currently in development.</span>
          </div>
        </v-flex>
      </v-layout>
    </section>

    <section>
      <v-container>
        <v-row>
          <v-col>
            <h2 class="text-center">Features</h2>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="6" lg="3">
            <h3>Zusammenfassung der Spielabende</h3>
            <span>
              Für jeden Spielabend kann eine Zusammenfassung angelegt werden, damit nichts vergessen wird. Diese kann
              von allen Mitspielern bearbeitet werden.
            </span>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <h3>Handouts</h3>
            <span>
              Jeder kann Dokumente hochladen und sie für alle oder nur einzelne Mitspieler freigeben. Inklusive
              automatischer Email-Benachrichtigung über neue Handouts. Zusätzlich können Handouts einem Spielabend
              zugewiesen werden und tauchen dann auch dort auf.
            </span>
          </v-col>
          <v-col cols="12" md="6" lg="3">
            <h3>Spielkarte</h3>
            <span>
              Der oder die Spielleiter/in kann beliebige Bilder hochladen und Marker darauf setzen. Diese können bewegt
              und verändert werden. Die Spieler können selbst ebenfalls Marker erstellen. Alle Änderungen werden in
              Echtzeit mit den anderen Teilnehmern geteilt.
            </span>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <section>
      <v-container grid-list-xl>
        <v-layout row wrap justify-center class="my-5">
          <v-flex xs12 sm6>
            <v-card class="elevation-0 transparent">
              <v-card-title primary-title class="layout justify-center">
                <div class="text-h4">Contact us</div>
              </v-card-title>
              <v-card-text>If you are interested in what we do, drop us a note:</v-card-text>
              <v-list class="transparent">
                <v-list-item>
                  <v-list-item-action>
                    <font-awesome-icon
                      class="blue--text text--lighten-2"
                      :icon="['far', 'envelope']"
                    />
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>
                      <a href="mailto:utilities@vjorngard.de">utilities@vjorngard.de</a>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </section>
  </v-container>
</template>
<script>
import loginform from './loginform';

export default {
  components: { loginform },
  data() {
    return {};
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>
<style scoped></style>
