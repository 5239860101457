import firebase from 'firebase/app';
import 'firebase/firestore';
import Fight from '@/interfaces/Fight';
import Migrator from './migrator';
import { WorldMarker } from '@/interfaces/Marker';
import { Quest, QuestTask, Journal, QuestTaskDescription } from '@/interfaces/journal/journal';
import Fighter from '@/interfaces/Fighter';
import { Round, RoundShort } from '@/interfaces/rounds/rounds';
import { GroupState } from '@/store/group/types';
import { User } from '@/interfaces/User';
import { Handout } from '@/interfaces/handouts/handouts';
import { AudioLibraryItem, LibraryItem } from '@/interfaces/world/library';
import { CharacterExportData } from './character/character';
import { BattleFieldData } from '@/interfaces/group/battle';
import { ClassCardData } from '@/interfaces/character/classCard';
import ValueAttribute from './attributes/valueAttribute';
import { Modifier } from './character/modifier';
import { Beast } from '@/interfaces/world/beast';
import { World } from '@/interfaces/world/world';
import { CreateUserReturn } from './firebasePayloads';
import UserData from '@/interfaces/UserData';

export default class FirebaseGateway {
  public static async createUser(email: string, password: string, username: string): Promise<CreateUserReturn> {
    const credential = await firebase.auth().createUserWithEmailAndPassword(email, password);

    // Create an empty user in firestore
    const userData: UserData = {
      currentFight: null,
      currentWorld: null,
      currentGroup: null,
      fights: [],
      name: username,
      state: 'online',
    };
    const user = credential.user;
    if (user === null) {
      throw new Error('User is null');
    }
    await firebase
      .firestore()
      .collection('users')
      .doc(user.uid)
      .set(userData);

    return {
      user,
      userData,
    };
  }
  public static loadUser(userId): Promise<UserData> {
    return firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .get()
      .then((docRef) => {
        return docRef.data() as UserData;
      });
  }
  /**
   * Loads all the fights
   */
  public static loadFights(): Promise<Fight[]> {
    const returnPromise = new Promise<Fight[]>((resolve) => {
      const promises: Array<Promise<firebase.firestore.QuerySnapshot>> = [];
      const allFights: Fight[] = [];

      const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
        if (user != null) {
          firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .get()
            .then((docRef) => {
              const data = docRef.data();
              if (typeof data === 'undefined') {
                return;
              }

              // Get all fights for this user
              const fightIds = data.fights;
              const migrator = new Migrator();

              // Loop over all fights and fetch the data
              fightIds.forEach((fightId) => {
                const promise = firebase
                  .firestore()
                  .collection('fights')
                  .where(firebase.firestore.FieldPath.documentId(), '==', fightId)
                  .get();
                promise.then((querySnapshot) => {
                  querySnapshot.forEach((doc) => {
                    const fight: Fight = doc.data() as Fight;
                    fight.id = fightId;
                    allFights.push(fight);
                  });
                });
                promises.push(promise);
              });

              Promise.all(promises).then(() => {
                // When all fights are loaded, unsubscribe from auth changes
                unsubscribe();
                resolve(allFights);
              });
            });
        } else {
          // When user is not logged in, resolve immediately
          resolve(allFights);
        }
      });
    });

    return returnPromise;
  }

  public static loadFighterPresets(userId) {
    const returnPromise = new Promise<Fighter[]>((resolve) => {
      const allFighterPresets: Fighter[] = [];

      const promise = firebase
        .firestore()
        .collection('fighter-presets/' + userId + '/presets')
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const fighter: Fighter = doc.data() as Fighter;
            allFighterPresets.push(fighter);
          });
          resolve(allFighterPresets);
          return allFighterPresets;
        });
      return promise;
    });
    return returnPromise;
  }

  /**
   * Save a round to the store. After the round was created/updated
   * save a small subset of this round to the group itself for easier overview.
   * @param groupId The id of the group this round belongs to
   * @param round The round that shall be created/updated, depending if id is null or not
   */
  public static saveRound(groupId: string, round: Round): Promise<string> {
    const collection = firebase.firestore().collection('groups/' + groupId + '/rounds');
    const roundPromise = new Promise<string>((resolve) => {
      if (round.id === null) {
        // Now add update time
        round.updatedAt = Math.round(new Date().getTime() / 1000);
        collection.add(round).then((docData) => {
          resolve(docData.id);
        });
      } else {
        // If this round is already existing, we need to make sure that it wasn't changed in the meantime
        const roundId = round.id;
        const roundDoc = collection.doc(round.id);
        roundDoc
          .get()
          .then((docData) => {
            const data = docData.data();
            if (!data) {
              throw new Error('No data for existing round found');
            }
            if (data.updatedAt > round.updatedAt) {
              throw new Error('Document was changed in the meantime');
            }
            // Saving is permitted, set updated time
            round.updatedAt = Math.round(new Date().getTime() / 1000);
            roundDoc.set(round, { merge: true }).then(() => {
              resolve(roundId);
            });
          })
          .catch((error) => {
            throw error;
          });
      }
    });

    // When the round is saved successfully, add the shortinfo to the group
    return roundPromise.then((roundId) => {
      return firebase.firestore().runTransaction((transaction) => {
        const document = firebase.firestore().doc('groups/' + groupId);
        return transaction.get(document).then((sfDoc) => {
          const data = sfDoc.data();
          if (typeof data === 'undefined') {
            throw new Error('Group document did not exist: ' + groupId);
          }
          const rounds: RoundShort[] = data.rounds ? data.rounds : [];
          const newRoundShort: RoundShort = {
            id: roundId,
            title: round.title,
            date: round.date,
          };
          const index = rounds.findIndex((localRound) => localRound.id === roundId);
          if (index > -1) {
            rounds[index] = newRoundShort;
          } else {
            rounds.push(newRoundShort);
          }
          transaction.update(document, { rounds });
          return roundId;
        });
      });
    });
  }

  public static async loadGroup(groupId: string) {
    const docData = await firebase
      .firestore()
      .doc('groups/' + groupId)
      .get();
    const data = docData.data();
    if (typeof data === 'undefined') {
      throw new Error('No data for groupId returned: ' + groupId);
    }

    // transform members from firebase format to interface
    const members: User[] = [];
    if (data.members) {
      for (const uid in data.members) {
        if (data.members[uid]) {
          members.push({
            uid,
            name: data.members[uid],
          });
        }
      }
    }
    let masters: string[] = [];
    if (Array.isArray(data.masters)) {
      masters = data.masters;
    }

    const groupState: GroupState = {
      members,
      masters,
      characterShorts: data.characters,
      characters: [],
      journal: data.journal,
      session: null,
      journalState: {},
      roundState: {},
      battleState: null,
      battleImages: [],
      roundsShort: data.rounds,
      handouts: [],
      rounds: [],
    };
    return groupState;
  }

  public static async loadRound(groupId: string, roundId: string) {
    const docData = await firebase
      .firestore()
      .doc('groups/' + groupId + '/rounds/' + roundId)
      .get();
    const data = docData.data();
    if (typeof data === 'undefined') {
      throw new Error('No data for round returned: ' + roundId);
    }
    const round = data as Round;
    round.id = docData.id;
    return round;
  }

  public static async saveCharacter(groupId: string, character: CharacterExportData) {
    const collection = await firebase.firestore().collection('groups/' + groupId + '/characters');
    if (character.characterId === null) {
      const result = await collection.add(character);
      return result.id;
    } else {
      const result = await collection.doc(character.characterId).update(character);
      return result;
    }
  }

  public static async updateCharacterClassCards(groupId: string, characterId: string, classCards: ClassCardData[]) {
    const collection = await firebase.firestore().collection('groups/' + groupId + '/characters');

    return await collection.doc(characterId).update('classcards', classCards);
  }

  public static async updateActiveClassCards(
    groupId: string,
    characterId: string,
    activeClassCards: Array<ClassCardData | null>,
  ) {
    const collection = await firebase.firestore().collection('groups/' + groupId + '/characters');
    return await collection.doc(characterId).update('activeClasscards', activeClassCards);
  }

  public static async updateCharacterCurrentValue(groupId: string, characterId: string, values: ValueAttribute[]) {
    const collection = await firebase.firestore().collection('groups/' + groupId + '/characters');
    return await collection.doc(characterId).update('currentValues', values);
  }

  public static async updateCharacterDoubts(groupId: string, characterId: string, values: ValueAttribute[]) {
    const collection = await firebase.firestore().collection('groups/' + groupId + '/characters');
    return await collection.doc(characterId).update('doubts', values);
  }

  public static async updateCharacterText(groupId: string, characterId: string, text: string) {
    const collection = await firebase.firestore().collection('groups/' + groupId + '/characters');
    return await collection.doc(characterId).update('miscText', text);
  }

  public static async saveCharacterModifiers(groupId: string, characterId: string, modifiers: Modifier[]) {
    const collection = await firebase.firestore().collection('groups/' + groupId + '/characters');
    return await collection.doc(characterId).update('modifiers', modifiers);
  }

  public static async loadCharacter(groupId: string, characterId: string) {
    const docData = await firebase
      .firestore()
      .doc('groups/' + groupId + '/characters/' + characterId)
      .get();
    const data = docData.data();
    if (typeof data === 'undefined') {
      throw new Error('No data for character returned: ' + characterId);
    }
    const character = data as CharacterExportData;
    character.characterId = docData.id;
    return character;
  }

  public static loadHandouts(groupId: string, currentUserId: string) {
    const handoutCollection = firebase.firestore().collection('groups/' + groupId + '/handouts');
    const groupHandouts = handoutCollection.where('visibility', '==', 'group').get();
    const personalHandouts = handoutCollection
      .where('visibility', '==', 'individual')
      .where('visibleFor', 'array-contains', currentUserId)
      .get();
    // Everyone should be able to see it's own handouts
    const uploadedHandouts = handoutCollection.where('uploadedBy', '==', currentUserId).get();
    return Promise.all([groupHandouts, personalHandouts, uploadedHandouts]).then((querySnaphotList) => {
      const allHandouts: Handout[] = [];
      querySnaphotList.forEach((querySnapshot) => {
        querySnapshot.docs.forEach((doc) => {
          const handout = doc.data() as Handout;
          handout.id = doc.id;
          // Prevent duplicates
          if (allHandouts.findIndex((localHandout) => localHandout.id === handout.id) === -1) {
            allHandouts.push(handout);
          }
        });
      });
      return allHandouts;
    });
  }

  public static uploadHandout(groupId: string, handout: Handout) {
    const collection = firebase.firestore().collection('groups/' + groupId + '/handouts');
    const roundPromise = new Promise<string>((resolve) => {
      if (handout.id === null) {
        collection.add(handout).then((docData) => {
          resolve(docData.id);
        });
      } else {
        const handoutId = handout.id;
        collection
          .doc(handout.id)
          .set(handout)
          .then(() => {
            resolve(handoutId);
          });
      }
    });
    return roundPromise;
  }

  public static loadBattleImages(groupId: string) {
    const collection = firebase.firestore().collection('groups/' + groupId + '/battleImages');

    return collection.get().then((querySnapshot) => {
      return querySnapshot.docs;
    });
  }

  public static uploadBattleImageData(groupId: string, battleImageData: BattleFieldData) {
    const collection = firebase.firestore().collection('groups/' + groupId + '/battleImages');

    return collection.add(battleImageData).then((docData) => {
      return docData.id;
    });
  }

  public static deleteHandout(groupId: string, handout: Handout) {
    const document = firebase.firestore().doc('groups/' + groupId + '/handouts/' + handout.id);
    return document.delete();
  }

  public static uploadLibraryItem(worldSlug: string, libraryItem: LibraryItem) {
    let collectionPath = 'world/' + worldSlug + '/library';
    if (typeof libraryItem.isPersonal !== 'undefined' && libraryItem.isPersonal === true) {
      collectionPath = 'users/' + libraryItem.uploadedBy + '/library';
    }
    const collection = firebase.firestore().collection(collectionPath);
    const roundPromise = new Promise<string>((resolve) => {
      if (libraryItem.id === null) {
        collection.add(libraryItem).then((docData) => {
          resolve(docData.id);
        });
      } else {
        const libraryItemId = libraryItem.id;
        collection
          .doc(libraryItem.id)
          .set(libraryItem)
          .then(() => {
            resolve(libraryItemId);
          });
      }
    });
    return roundPromise;
  }

  public static uploadAudioLibraryItem(worldSlug: string, libraryItem: AudioLibraryItem) {
    const collectionPath = 'world/' + worldSlug + '/audio';

    const collection = firebase.firestore().collection(collectionPath);
    const roundPromise = new Promise<string>((resolve) => {
      if (libraryItem.id === null) {
        collection.add(libraryItem).then((docData) => {
          resolve(docData.id);
        });
      } else {
        const libraryItemId = libraryItem.id;
        collection
          .doc(libraryItem.id)
          .set(libraryItem)
          .then(() => {
            resolve(libraryItemId);
          });
      }
    });
    return roundPromise;
  }

  public static removeLibraryItem(worldSlug: string, libraryItem: LibraryItem) {
    const doc = firebase.firestore().doc('world/' + worldSlug + '/library/' + libraryItem.id);
    return doc.delete();
  }

  public static loadLibrary(worldSlug: string, userId: string) {
    const libraryCollection = firebase.firestore().collection('world/' + worldSlug + '/library');
    const libraryCollectionPersonal = firebase.firestore().collection('users/' + userId + '/library');

    const uploadedHandouts = libraryCollection.get();
    const uploadedPersonalHandouts = libraryCollectionPersonal.get();
    const allPromise = Promise.all([uploadedHandouts, uploadedPersonalHandouts]);
    return allPromise.then(([querySnapshot, querySnapshots]) => {
      const library: LibraryItem[] = [];
      const docs = [...querySnapshot.docs, ...querySnapshots.docs];
      docs.forEach((doc) => {
        const item = doc.data() as LibraryItem;
        item.id = doc.id;
        library.push(item);
      });
      return library;
    });
  }

  public static loadAudioLibrary(worldSlug: string) {
    const libraryCollection = firebase.firestore().collection('world/' + worldSlug + '/audio');

    const uploadedHandouts = libraryCollection.get();
    return uploadedHandouts.then((querySnapshot) => {
      const library: AudioLibraryItem[] = [];
      querySnapshot.docs.forEach((doc) => {
        const item = doc.data() as AudioLibraryItem;
        item.id = doc.id;
        library.push(item);
      });
      return library;
    });
  }

  public static removeFighterPreset(userId: string, presetId: string) {
    return firebase
      .firestore()
      .doc('fighter-presets/' + userId + '/presets/' + presetId)
      .delete();
  }

  public static getVersionFromRealtime() {
    return firebase
      .database()
      .ref('/global')
      .once('value')
      .then((snapshot) => {
        const data = snapshot.val();
        return data.version;
      });
  }

  public static async uploadClassCard(worldId: string, classCardData: ClassCardData) {
    const collection = firebase.firestore().collection('world/' + worldId + '/rules/cards/classcards');
    if (classCardData.id === null) {
      const result = await collection.add(classCardData);
      classCardData.id = result.id;
      return classCardData;
    } else {
      await collection.doc(classCardData.id).set(classCardData);
      return classCardData;
    }
  }

  public static async loadClassCards(worldId: string, onlyClass: string | null) {
    const collection = firebase.firestore().collection('world/' + worldId + '/rules/cards/classcards');
    if (onlyClass !== null) {
      const queryResultForCLass = await collection.where('classValue', '==', onlyClass).get();
      return queryResultForCLass.docs;
    }
    const queryResult = await collection.get();
    return queryResult.docs;
  }

  public static async loadClassCardsByIds(worldId: string, classCardIds: string[]) {
    const collection = firebase.firestore().collection('world/' + worldId + '/rules/cards/classcards');

    // We need to split the classcardsIds into batches of 10
    const numBatches = Math.ceil(classCardIds.length / 10);
    const docs: firebase.firestore.DocumentData[] = [];
    for (let i = 0; i < numBatches; i++) {
      const batch = classCardIds.slice(i * 10, (i + 1) * 10);
      const queryResultForCards = await collection.where(firebase.firestore.FieldPath.documentId(), 'in', batch).get();
      docs.push(...queryResultForCards.docs);
    }
    return docs;
  }

  /**
   * BESTIARY
   */
  public static async loadBeasts(groupId: string, isGroupView: boolean | null) {
    const callParams: { groupId: string; groupView?: boolean } = { groupId };
    if (isGroupView === true) {
      callParams.groupView = true;
    }
    const getBeasts = firebase
      .app()
      .functions('europe-west1')
      .httpsCallable('getBeasts');
    const beastData = await getBeasts(callParams);
    return beastData.data as Beast[];
  }

  public static async createBeast(world: string, beast: Beast) {
    const collection = firebase.firestore().collection('world/' + world + '/bestiary/');
    if (beast.id === null) {
      const beastDoc = await collection.add(beast);
      beast.id = beastDoc.id;
      return beast;
    } else {
      await collection.doc(beast.id).set(beast);
      return beast;
    }
  }

  public static async getWorld(worldSlug: string) {
    const doc = await firebase
      .firestore()
      .doc('world/' + worldSlug)
      .get();
    const data = doc.data() as World;
    data.slug = doc.id;
    return data;
  }

  public saveFight(currentFight: Fight): Promise<void> {
    return firebase
      .firestore()
      .doc('fights/' + currentFight.id)
      .set(currentFight)
      .catch((reason) => {
        console.log('saveFight failed', reason);
      });
  }

  /**
   * Saves a single marker by either adding it, if it doesn't have an id
   * or overwriting the existing one
   * @param marker
   */
  public saveMarker(world: string, marker: WorldMarker): Promise<string> {
    const collection = firebase.firestore().collection('world/' + world + '/mapMarkers');

    // If this is a new marker (doesn't have id) add it to collection
    if (typeof marker.id === 'undefined') {
      const returnPromis = collection.add(marker);

      // Return the newly created id
      return returnPromis.then((document) => {
        return document.id;
      });

      // If it is an existing marker, overwrite it
    } else {
      // Create new promise to resolve with id
      const markerId: string = marker.id;
      return new Promise((resolve) => {
        collection
          .doc(markerId)
          .set(marker)
          .then(() => {
            resolve(markerId);
          });
      });
    }
  }

  public loadMapMarkers(world: string): Promise<firebase.firestore.QuerySnapshot> {
    return firebase
      .firestore()
      .collection('world/' + world + '/mapMarkers')
      .get();
  }

  /**
   *
   * @param userId
   * @param currentFightId
   */
  public setCurrentFightForUser(userId: string, currentFightId: string) {
    firebase
      .firestore()
      .collection('users')
      .doc(userId)
      .set({ currentFight: currentFightId }, { merge: true });
  }

  /**
   * Loads the fight with the passed id and returns the document
   * @param fightId
   */
  public getFight(fightId: string): Promise<firebase.firestore.DocumentSnapshot> {
    return firebase
      .firestore()
      .collection('fights')
      .doc(fightId)
      .get();
  }

  public addOrUpdateFightPreset(userId: string, preset: Fighter) {
    return firebase
      .firestore()
      .doc('fighter-presets/' + userId + '/presets/' + preset.id)
      .set(preset);
  }

  public saveAccountName(userId: string, username: string) {
    return firebase
      .firestore()
      .doc('users/' + userId)
      .set({ name: username }, { merge: true });
  }

  /**
   * @param groupId
   * @param location
   */
  public getPublicPersons(groupId): Promise<firebase.firestore.QuerySnapshot> {
    const collection = firebase
      .firestore()
      .collection('database')
      .doc(groupId)
      .collection('people');

    const publicPersonsQuery = collection
      .where('private', '==', false)
      .get()
      .catch((error) => {
        throw new Error('Error fetching global persons ' + error);
      });
    return publicPersonsQuery;
  }

  /**
   *
   */
  public getPrivatePersons(groupId, userId) {
    const collection = firebase
      .firestore()
      .collection('database')
      .doc(groupId)
      .collection('people');

    const privatePersons = collection
      .where('private', '==', true)
      .where('addedBy', '==', userId)
      .get()
      .catch((error) => {
        throw new Error('Error fetching private persons ' + error);
      });

    return privatePersons;
  }

  /**
   * Fetches all available worlds for that user.
   * Either the world is public, or it is private and he has access to it.
   * @param userId
   */
  public getWorlds(userId: string | null): Promise<firebase.firestore.QuerySnapshot[]> {
    const publicWorldsPromise = firebase
      .firestore()
      .collection('world')
      .where('public', '==', true)
      .get();
    const promises = [publicWorldsPromise];

    if (userId !== null) {
      const memberWorldsPromise = firebase
        .firestore()
        .collection('world')
        .where('public', '==', false)
        .where('members', 'array-contains', userId)
        .get();
      promises.push(memberWorldsPromise);
    }

    return Promise.all(promises);
  }

  public async uploadFile(
    file: File,
    path: string,
    preventOverwrite = false,
    progressFunction: null | CallableFunction = null,
    ): Promise<string> {
    // Make sure path is pointing to a folder
    if (!path || path[path.length - 1] !== '/') {
      throw new Error('path is not a folder: ' + path);
    }

    // We need to check if this file is already existing
    const fileRef = firebase.storage().ref(path + file.name);
    if (preventOverwrite) {
      try {
        await fileRef.getDownloadURL();

        // If we get an url, this means this file exist
        throw new Error('file_exists');
      } catch (error) {
        if (typeof error === 'object' && error !== null && error['code'] === 'storage/object-not-found') {
          await fileRef.put(file);
          return fileRef.getDownloadURL();
        } else {
          // Any other error is not desired
          throw error;
        }
      }
    } else {
      const task = fileRef.put(file);
      if (progressFunction !== null) {
        task.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
          const progressInPercent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
          progressFunction(progressInPercent);
        });
      }
      return task.then(() => {
        return fileRef.getDownloadURL();
      });
    }
  }

  public getJournal(groupId: string) {
    const groupDocRef = firebase
      .firestore()
      .collection('groups')
      .doc(groupId)
      .get();
    return groupDocRef.then((groupDoc) => {
      const data = groupDoc.data();
      if (typeof data === 'undefined') {
        throw new Error('group not found: ' + groupId);
      }
      let journal = data.journal;
      if (!journal) {
        journal = { quests: [] };
      }
      return Promise.resolve(journal);
    });
  }

  /**
   * ------------------------------------------------------
   *                QUEST RELATED STUFF
   * ------------------------------------------------------
   */

  /**
   * Add a new quest
   * @param groupId string
   * @param quest Quest
   */
  public addQuest(groupId: string, quest: Quest) {
    // Create a reference to the SF doc.
    const sfDocRef = firebase
      .firestore()
      .collection('groups')
      .doc(groupId);

    return firebase
      .firestore()
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(sfDocRef).then((sfDoc) => {
          if (typeof sfDoc === 'undefined' || !sfDoc.exists) {
            throw new Error('Document does not exist!');
          }

          const data = sfDoc.data();
          if (typeof data === 'undefined') {
            return;
          }

          const journal = data.journal ? data.journal : { quests: [] };
          journal.quests.push(quest);
          transaction.update(sfDocRef, { journal });
        });
      })
      .then(() => {
        console.log('Transaction successfully committed!');
      })
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  }

  public deleteQuest(groupId: string, questId) {
    // Create a reference to the SF doc.
    const sfDocRef = firebase
      .firestore()
      .collection('groups')
      .doc(groupId);

    return firebase.firestore().runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(sfDocRef).then((sfDoc) => {
        if (typeof sfDoc === 'undefined' || !sfDoc.exists) {
          throw new Error('Document does not exist!');
        }

        const data = sfDoc.data();
        if (typeof data === 'undefined') {
          return;
        }

        const journal: Journal = data.journal ? data.journal : { quests: [] };

        // Find the quest with the id and splice it
        const questIndex = journal.quests.findIndex((quest) => quest.id === questId);
        if (questIndex === -1) {
          throw new Error('Quest with ID ' + questId + ' not found in journal');
        }
        journal.quests.splice(questIndex, 1);
        transaction.update(sfDocRef, { journal });
      });
    });
  }

  public updateQuest(groupId: string, quest) {
    // Create a reference to the SF doc.
    const sfDocRef = firebase
      .firestore()
      .collection('groups')
      .doc(groupId);

    return firebase.firestore().runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(sfDocRef).then((sfDoc) => {
        if (typeof sfDoc === 'undefined' || !sfDoc.exists) {
          throw new Error('Document does not exist!');
        }

        const data = sfDoc.data();
        if (typeof data === 'undefined') {
          return;
        }

        const journal: Journal = data.journal;

        // Find the quest with the id and splice it
        const questIndex = journal.quests.findIndex((localQuest) => localQuest.id === quest.id);
        if (questIndex === -1) {
          throw new Error('Quest with ID ' + quest.id + ' not found in journal');
        }
        journal.quests[questIndex] = quest;
        return transaction.update(sfDocRef, { journal });
      });
    });
  }

  public changeQuestOrder(groupId: string, quest, newIndex) {
    // Create a reference to the SF doc.
    const sfDocRef = firebase
      .firestore()
      .collection('groups')
      .doc(groupId);

    return firebase.firestore().runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(sfDocRef).then((sfDoc) => {
        if (typeof sfDoc === 'undefined' || !sfDoc.exists) {
          throw new Error('Document does not exist!');
        }

        const data = sfDoc.data();
        if (typeof data === 'undefined') {
          return;
        }

        const journal: Journal = data.journal;

        // Find the quest with the id and splice it
        const questIndex = journal.quests.findIndex((localQuest) => localQuest.id === quest.id);
        if (questIndex === -1) {
          throw new Error('Quest with ID ' + quest.id + ' not found in journal');
        }

        // Make sure the index has not changed in the meantime
        if (journal.quests[questIndex].index !== quest.index) {
          throw new Error('Quest has different index in the meantime: ' + journal[questIndex].index);
        }

        // Search the quest with the new index and swap those
        const swappedQuest = journal.quests.find((localQuest) => {
          return localQuest.index === newIndex && localQuest.isMainQuest === quest.isMainQuest;
        });

        if (!swappedQuest) {
          throw new Error('Quest with newIndex ' + newIndex + ' not found');
        }
        swappedQuest.index = quest.index;
        journal.quests[questIndex].index = newIndex;

        return transaction.update(sfDocRef, { journal });
      });
    });
  }

  public addQuestTask(groupId: string, questId: string, task: QuestTask) {
    // Create a reference to the SF doc.
    const sfDocRef = firebase
      .firestore()
      .collection('groups')
      .doc(groupId);

    return firebase
      .firestore()
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(sfDocRef).then((sfDoc) => {
          if (typeof sfDoc === 'undefined' || !sfDoc.exists) {
            throw new Error('Document does not exist!');
          }

          const data = sfDoc.data();
          if (typeof data === 'undefined') {
            return;
          }

          const journal = data.journal as Journal;
          if (typeof journal === 'undefined' || !journal) {
            throw new Error('Journal not found on group ' + groupId);
          }

          if (typeof journal.quests === 'undefined' || !Array.isArray(journal.quests) || journal.quests.length === 0) {
            throw new Error('Journal has no quests in group ' + groupId);
          }

          const quests: Quest[] = data.journal.quests;
          const questIndex = quests.findIndex((existingQuest) => existingQuest.id === questId);
          if (questIndex === -1) {
            throw new Error('Quest with id ' + questId + ' not found');
          }
          if (typeof quests[questIndex].tasks === 'undefined') {
            quests[questIndex].tasks = [];
          }
          quests[questIndex].tasks.push(task);

          // Override original one
          journal.quests = quests;
          transaction.update(sfDocRef, { journal });
        });
      })
      .then(() => {
        console.log('Transaction successfully committed!');
      })
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  }

  public addQuestTaskDescription(
    groupId: string,
    questId: string,
    questTaskId: string,
    description: QuestTaskDescription,
  ) {
    // Create a reference to the SF doc.
    const sfDocRef = firebase
      .firestore()
      .collection('groups')
      .doc(groupId);

    return firebase
      .firestore()
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(sfDocRef).then((sfDoc) => {
          if (typeof sfDoc === 'undefined' || !sfDoc.exists) {
            throw new Error('Document does not exist!');
          }

          const data = sfDoc.data();
          if (typeof data === 'undefined') {
            return;
          }

          const journal = data.journal as Journal;
          if (typeof journal === 'undefined' || !journal) {
            throw new Error('Journal not found on group ' + groupId);
          }

          if (typeof journal.quests === 'undefined' || !Array.isArray(journal.quests) || journal.quests.length === 0) {
            throw new Error('Journal has no quests in group ' + groupId);
          }

          const quests: Quest[] = data.journal.quests;
          const questIndex = quests.findIndex((existingQuest) => existingQuest.id === questId);
          if (questIndex === -1) {
            throw new Error('Quest with id ' + questId + ' not found');
          }
          if (typeof quests[questIndex].tasks === 'undefined') {
            throw new Error('Quest ' + questId + ' has no tasks ' + groupId);
          }
          const tasks = quests[questIndex].tasks;
          const questTaskIndex = tasks.findIndex((existingTask) => existingTask.id === questTaskId);
          if (questTaskIndex === -1) {
            throw new Error('QuestTask with id ' + questTaskId + ' not found');
          }
          quests[questIndex].tasks[questTaskIndex].descriptions.push(description);

          // Override original one
          journal.quests = quests;
          transaction.update(sfDocRef, { journal });
        });
      })
      .then(() => {
        console.log('Transaction successfully committed!');
      })
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  }

  public deleteQuestTask(groupId: string, questId: string, questTaskId: string) {
    // Create a reference to the SF doc.
    const sfDocRef = firebase
      .firestore()
      .collection('groups')
      .doc(groupId);

    return firebase
      .firestore()
      .runTransaction((transaction) => {
        // This code may get re-run multiple times if there are conflicts.
        return transaction.get(sfDocRef).then((sfDoc) => {
          if (typeof sfDoc === 'undefined' || !sfDoc.exists) {
            throw new Error('Document does not exist!');
          }

          const data = sfDoc.data();
          if (typeof data === 'undefined') {
            return;
          }

          const journal = data.journal as Journal;
          if (typeof journal === 'undefined' || !journal) {
            throw new Error('Journal not found on group ' + groupId);
          }

          if (typeof journal.quests === 'undefined' || !Array.isArray(journal.quests) || journal.quests.length === 0) {
            throw new Error('Journal has no quests in group ' + groupId);
          }

          const quests: Quest[] = data.journal.quests;
          const questIndex = quests.findIndex((existingQuest) => existingQuest.id === questId);
          if (questIndex === -1) {
            throw new Error('Quest with id ' + questId + ' not found');
          }
          if (typeof quests[questIndex].tasks === 'undefined') {
            throw new Error('Quest ' + questId + ' has no tasks ' + groupId);
          }
          const tasks = quests[questIndex].tasks;
          const questTaskIndex = tasks.findIndex((existingTask) => existingTask.id === questTaskId);
          if (questTaskIndex === -1) {
            throw new Error('QuestTask with id ' + questTaskId + ' not found');
          }
          quests[questIndex].tasks.splice(questTaskIndex, 1);

          // Override original one
          journal.quests = quests;
          transaction.update(sfDocRef, { journal });
        });
      })
      .then(() => {
        console.log('Transaction successfully committed!');
      })
      .catch((error) => {
        console.log('Transaction failed: ', error);
      });
  }
}
