import firebase from 'firebase/app';
import { Commit } from 'vuex';
import Session from '@/interfaces/group/session';
import { BattleMarker, BattleData } from '@/interfaces/group/battle';

export default class FirebaseRealtime {
  public static watchBattle(commit, groupId: string) {
    const battleRef = firebase.database().ref('/groups/' + groupId + '/battle');
    battleRef.on('value', (snapshot) => {
      commit('setBattleState', snapshot.val());
    });
    FirebaseRealtime.listeners.push(battleRef);
  }

  public static watchJournal(commit, groupId: string) {
    const journalRef = firebase.database().ref('/groups/' + groupId + '/journal');
    journalRef.on('value', (snapshot) => {
      let value = snapshot.val();
      if (value === null) {
        value = {};
      }
      commit('setJournalState', value);
    });
    FirebaseRealtime.listeners.push(journalRef);
  }

  public static watchRounds(commit, groupId: string) {
    const roundRef = firebase.database().ref('/groups/' + groupId + '/rounds');
    roundRef.on('value', (snapshot) => {
      let value = snapshot.val();
      if (value === null) {
        value = {};
      }
      commit('setRoundState', value);
    });
    FirebaseRealtime.listeners.push(roundRef);
  }

  public static updateMarker(groupId: string, marker: BattleMarker) {
    const markerRef = firebase.database().ref('/groups/' + groupId + '/battle/markers/' + marker.id);
    // In case we disconnect, remove this lock
    return markerRef.set(marker);
  }

  public static removeMarker(groupId: string, markerId: string) {
    const markerRef = firebase.database().ref('/groups/' + groupId + '/battle/markers/' + markerId);
    // In case we disconnect, remove this lock
    return markerRef.remove();
  }

  public static newBattle(groupId: string, battleImageUrl: string) {
    const battleData: BattleData = {
      imageUrl: battleImageUrl,
      markers: [],
    };
    const markerRef = firebase.database().ref('/groups/' + groupId + '/battle/');
    return markerRef.set(battleData);
  }

  public static flagQuestInJournal(groupId: string, questId: string, userUid: string) {
    const journalRef = firebase.database().ref('/groups/' + groupId + '/journal/' + questId);
    // In case we disconnect, remove this lock
    journalRef
      .onDisconnect()
      .remove()
      // When the server has acknowledged the disconnect, now it's save to mark the journal as locked
      .then(() => {
        journalRef.set(userUid);
      });
  }

  public static unflagQuestInJournal(groupId: string, questId: string) {
    const journalRef = firebase.database().ref('/groups/' + groupId + '/journal/' + questId);
    journalRef.remove();
  }

  public static flagRound(groupId: string, roundId: string, userUid: string) {
    const journalRef = firebase.database().ref('/groups/' + groupId + '/rounds/' + roundId);
    // In case we disconnect, remove this lock
    journalRef
      .onDisconnect()
      .remove()
      // When the server has acknowledged the disconnect, now it's save to mark the journal as locked
      .then(() => {
        journalRef.set(userUid);
      });
  }

  public static unflagRound(groupId: string, questId: string) {
    const journalRef = firebase.database().ref('/groups/' + groupId + '/rounds/' + questId);
    journalRef.remove();
  }

  /**
   * Goes over all saved listeners and calls them which interrupts the liseners
   */
  public static clearListeners() {
    FirebaseRealtime.listeners.forEach((reference) => {
      reference.off();
    });

    // Afterwards, remove references
    FirebaseRealtime.listeners = [];
  }

  public static watchSession(commit: Commit, groupId: string) {
    const sessionRef = firebase.database().ref('/sessions/' + groupId);
    const watchPromise = new Promise((resolve) => {
      sessionRef.on('value', (snapshot) => {
        commit('setSessionState', snapshot.val());
        resolve(snapshot.val());
      });
    });

    FirebaseRealtime.listeners.push(sessionRef);
    return watchPromise;
  }

  public static startSession(groupId: string, userId: string) {
    const sessionRef = firebase.database().ref('/sessions/' + groupId);
    const newSession: Session = { owner: userId, started: firebase.database.ServerValue.TIMESTAMP, audio: false };
    sessionRef.set(newSession);
  }

  public static setSessionAudio(groupId: string, audioState: boolean) {
    const sessionRef = firebase.database().ref('/sessions/' + groupId);
    sessionRef.update({ audio: audioState });
  }

  public static stopSession(groupId: string) {
    const sessionRef = firebase.database().ref('/sessions/' + groupId);
    sessionRef.once('value').then((snapshot) => {
      const sessionMessagesRef = firebase.database().ref('/sessions/messages/' + snapshot.val().owner);
      sessionMessagesRef.remove();
    });
    sessionRef.remove();
  }

  public static sendSessionMessage(ownerId: string, userId: string, data: any) {
    firebase
      .database()
      .ref('/sessions/messages/' + ownerId)
      .push({ sender: userId, message: data });
  }

  public static watchSessionMessages(userId: string, callback: CallableFunction) {
    firebase
      .database()
      .ref('/sessions/messages/' + userId)
      .on('child_added', (snapshot) => {
        callback(snapshot.val());
      });
  }

  private static listeners: firebase.database.Reference[] = [];
}
