export default [
  'red',
  'orange',
  'yellow',
  'green',
  'teal',
  'cyan',
  'blue',
  'indigo',
  'brown',
  'white',
  'grey',
  'black',
];
