import { GetterTree } from 'vuex';
import { FightState } from './types';
import { RootState } from '../types';
import Fight from '@/interfaces/Fight';
import Fighter from '@/interfaces/Fighter';

export const getters: GetterTree<FightState, RootState> = {
  /**
   * Returns the current fight for this user
   * @param state
   */
  currentFight(state): Fight | null {
    return state.currentFight;
  },

  /**
   * Gets all fights for this user
   * @param state
   */
  getFights(state): Fight[] {
    return state.fights;
  },

  /**
   * Returns all saved presets of fighters
   */
  fighterPresets(state): Fighter[] {
    const presets = JSON.parse(JSON.stringify(state.presets));
    presets.sort((fighterA, fighterB) => {
      return fighterA.name > fighterB.name ? 1 : -1;
    });
    return presets;
  },
};
