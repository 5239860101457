import { GetterTree } from 'vuex';
import { GroupState } from './types';
import { RootState } from '../types';
import { Handout } from '@/interfaces/handouts/handouts';

export const getters: GetterTree<GroupState, RootState> = {
  /**
   * Returns the current fight for this user
   * @param state
   */
  journal(state) {
    return state.journal;
  },

  journalState(state) {
    return state.journalState;
  },

  roundState(state) {
    return state.roundState;
  },

  battleState(state) {
    return state.battleState;
  },

  battleImages(state) {
    return state.battleImages;
  },

  roundShorts(state) {
    return state.roundsShort;
  },

  characterShorts(state) {
    return state.characterShorts;
  },

  getRoundById(state) {
    return (roundId: string) => {
      return state.rounds.find((round) => round.id === roundId);
    };
  },

  getCharacter(state) {
    return (characterId: string) => {
      const foundCharacter = state.characters.find((character) => character.characterId === characterId);
      if (typeof foundCharacter === 'undefined') {
        throw new Error(characterId + ' not in state');
      }
      return foundCharacter;
    };
  },

  getCharacterActiveCards(state) {
    return (characterId: string) => {
      const foundCharacter = state.characters.find((character) => character.characterId === characterId);
      if (typeof foundCharacter === 'undefined') {
        throw new Error(characterId + ' not in state');
      }
      return foundCharacter.activeClasscards;
    };
  },

  members(state) {
    return state.members;
  },

  handouts(state): Handout[] {
    return state.handouts;
  },

  session(state) {
    return state.session;
  },
};
