import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import mutations from './mutations';
import { getters } from './getters';
import actions from './actions';
import { fight } from './fight/index';
import { world } from './world/index';
import { group } from './group/index';

Vue.use(Vuex);

declare var process: {
  env: {
    VUE_APP_VERSION: string;
    NODE_ENV: string;
    VUE_APP_FIREBASE_TARGET: string;
    BASE_URL: string;
  };
};

const store: StoreOptions<RootState> = {
  state: {
    user: null,
    settings: {},
    groups: [],
    groupInvitations: [],
    currentGroup: null,
    currentGroupData: null,
    worldData: {
      locations: [],
      misc: [],
    },
    userData: null,
    messages: [],
    presets: [],
    isSaving: false,
    lastSave: 0,
    theme: {
      background: 'Background0.jpg',
      opacity: 50,
      colors: { primary: '#ce6739', secondary: '#52404c', accent: '#375782' },
    },
    version: process.env.VUE_APP_VERSION,
    userLog: [],
  },
  mutations,
  getters,
  actions,
  modules: {
    fight,
    world,
    group,
  },
  strict: process.env.NODE_ENV !== 'production',
};

export default new Vuex.Store<RootState>(store);
