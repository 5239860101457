import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { GroupState } from './types';
import { RootState } from '../types';

export const state: GroupState = {
  members: [],
  masters: [],
  characterShorts: [],
  characters: [],
  journal: null,
  session: null,
  journalState: {},
  roundState: {},
  battleState: null,
  battleImages: [],
  roundsShort: [],
  rounds: [],
  handouts: [],
};

const namespaced: boolean = false;

export const group: Module<GroupState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
